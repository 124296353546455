import { route } from "../utils/router";
import { getFlashcardByID } from "./databaseManager";
import NotFound from "../pages/Not-found-page/NotFound";
import FlashCardView from "../pages/Flashcard-view/flashCardView";

export function runBGChecker() {
  document.addEventListener('DOMContentLoaded', function() {
    const currentDomain = window.location.origin;
    const currentPath = window.location.pathname;

    if (currentPath.startsWith('/flashcard/')) {
      const flashcardId = currentPath.split('/flashcard/')[1];
      if (flashcardId) {
        runBGListener(flashcardId);
      }
    }
  });
}

// TODO: add sign in before access is granted

function runBGListener(id) {
  // load the specified flashcard
  getFlashcardByID(id).then((response) => {
    if (response.success) {
      let flashcard = response.data;
      flashcard.progress = 0;
      flashcard.cardSorting = false;
      flashcard.shuffleCards = false;

      console.log("Flashcard loaded share: ", flashcard);

      route(<FlashCardView
        data={flashcard}
        fillScreen
        isDeletable={false}
        isEditable={false}
        updateToCloud={false}
      />)
    } else {
      console.error("Error getting flashcard by ID: ", response.error);
      route(<NotFound />);
    }
  }).catch((error) => {
    console.error("Error getting flashcard by ID: ", error);
  });
}
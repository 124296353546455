import React from "react";

// Import the styles
import "./NotFound.scss";
import NiceButton from "../../components/NiceButton/_layout";
import { route } from "../../utils/router";
import HomePage from "../Home-page/homePage";

function NotFound() {
  return (
    <div className="not-found-main">
      <h1>Page Not Found</h1>
      <NiceButton
        text="Go to Home Page"
        width="200px"
        onClick={() => {
          route(<HomePage />);
        }}
      />
    </div>
  );
}

export default NotFound;
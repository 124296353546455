import React, { useEffect } from 'react';
import { Button } from '@mui/material';

import './_styles.scss';
import logo from '../../assets/logo/logo.svg';
import geminiGoogle from '../../assets/images/Google Gemini logo.png';

import SignIn from '../sign-in/signIn';

import { route } from '../../utils/router';


function LandingPage({ className }) {
  useEffect(() => {
    const observerX = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('card-in');
          } else {
            entry.target.classList.remove('card-in');
          }
        });
      },
      {
        root: null,
        threshold: 0,
      }
    );

    const observerPosX = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in-X');
          } else {
            entry.target.classList.remove('slide-in-X');
          }
        });
      },
      {
        root: null,
        threshold: 0,
      }
    );

    const observerY = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in-Y');
          } else {
            entry.target.classList.remove('slide-in-Y');
          }
        });
      },
      {
        root: null,
        threshold: 0,
      }
    );

    const observerOpacity = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
          } else {
            entry.target.classList.remove('fade-in');
          }
        });
      },
      {
        root: null,
        threshold: 0,
      }
    );

    const featuresElements = document.querySelectorAll('.feature-anim');
    const startElement = document.querySelector('.h1-start');
    const joinElement = document.querySelector('.h1-join');
    const joinButton = document.querySelector('.join-btn');
    const aiTextElem = document.querySelector('.h1-ai');
    const geminiLogo = document.querySelector('.gemini-logo');

    observerY.observe(startElement);
    observerY.observe(joinElement);
    observerPosX.observe(joinButton);
    observerY.observe(aiTextElem);
    observerOpacity.observe(geminiLogo);

    featuresElements.forEach((element) => {
      observerX.observe(element);
    });

    return () => {
      featuresElements.forEach((element) => {
        observerX.unobserve(element);
      });

      observerY.unobserve(startElement);
      observerY.unobserve(joinElement);
      observerPosX.unobserve(joinButton);

      observerY.unobserve(aiTextElem);
      observerOpacity.unobserve(geminiLogo);
    };
  }, []);
  
  return (
    <div className={`main-landing-page ${className}`}>
      <img src={logo} alt='Logo' />

      <main>
        <section id='start'>
          <h1 className='h1-start'>
            The <span><em>BEST</em></span> way to learn and study with flashcards.
          </h1>
        </section>

        <section id='features'>
          <div className="feature-anim flash-land" pos="left">

          </div>

          <div className="feature-anim flash-land" pos="middle">
            <h2>
              With amazing <span><em>features</em></span>, <br /><br />
              and insane <span><em>customization</em></span>!
            </h2>
          </div>

          <div className="feature-anim flash-land" pos="right">

          </div>
        </section>

        <section id='ai-promo'>
          <h1 className='h1-ai'>
            <span><em>AI</em></span> powered flashcards, <br />
            for the <span><em>best</em></span> learning experience!
          </h1>
          {/* <img src={geminiLogo} alt='Gemini Logo' className='gemini-logo' /> */}
          <img src={geminiGoogle} alt="Gemini" className='gemini-logo' />
        </section>

        <section id='join'>
          <h1 className='h1-join'>
            Join us today and start <span><em>learning</em></span> with Flashy!
          </h1>
          <Button
            className='join-btn'
            variant='contained'
            size='large'
            onClick={() => route(<SignIn />)}
            sx={{
              backgroundColor: '#003687',
              color: 'white',
              width: '300px',
              fontStyle: 'italic',
              transition: 'all .4s ease',
              '&:hover': {
                backgroundColor: '#005592',
                transform: 'scale(1.05)',
                textShadow: '0 0 10px white',
                boxShadow: '0 0 10px white',
              }
            }}
          >
            <p>Join Now!</p>
          </Button>
        </section>
      </main>
    </div>
  );
}

export default LandingPage;
export function isMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (window.innerWidth < 768) {
    return true;
  }

  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase());
}

export function checkIfOffline() {
  const isOnline = navigator.onLine;
  console.log("Online:", isOnline);
  return !isOnline;
}

export function adjustOnScreenSize() {
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;

  let breakpoints = [
    [ 354, 786, 1080, 1280, 1920, 2560, 3840, 4320, 7680 ],
    [ 786, 902, 1180, 1366, 1600, 1920, 2560, 3840, 4320 ],
  ]

  let widthK, heightK;

  if (viewportHeight < breakpoints[1][0] && viewportHeight > 0) {
    heightK = 0.5;
  } else if (viewportHeight < breakpoints[1][1] && viewportHeight >= breakpoints[1][0]) {
    heightK = 1;
  } else if (viewportHeight < breakpoints[1][2] && viewportHeight >= breakpoints[1][1]) {
    heightK = 2;
  } else if (viewportHeight < breakpoints[1][3] && viewportHeight >= breakpoints[1][2]) {
    heightK = 3;
  } else if (viewportHeight < breakpoints[1][4] && viewportHeight >= breakpoints[1][3]) {
    heightK = 4;
  } else if (viewportHeight < breakpoints[1][5] && viewportHeight >= breakpoints[1][4]) {
    heightK = 5;
  } else if (viewportHeight < breakpoints[1][6] && viewportHeight >= breakpoints[1][5]) {
    heightK = 6;
  } else if (viewportHeight < breakpoints[1][7] && viewportHeight >= breakpoints[1][6]) {
    heightK = 7;
  } else if (viewportHeight < breakpoints[1][8] && viewportHeight >= breakpoints[1][7]) {
    heightK = 8;
  } else {
    heightK = 9;
  }
  
  if (viewportWidth < breakpoints[0][0] && viewportWidth > 0) {
    widthK = 0.5;
  } else if (viewportWidth < breakpoints[0][1] && viewportWidth >= breakpoints[0][0]) {
    widthK = 1;
  } else if (viewportWidth < breakpoints[0][2] && viewportWidth >= breakpoints[0][1]) {
    widthK = 2;
  } else if (viewportWidth < breakpoints[0][3] && viewportWidth >= breakpoints[0][2]) {
    widthK = 3;
  } else if (viewportWidth < breakpoints[0][4] && viewportWidth >= breakpoints[0][3]) {
    widthK = 4;
  } else if (viewportWidth < breakpoints[0][5] && viewportWidth >= breakpoints[0][4]) {
    widthK = 5;
  } else if (viewportWidth < breakpoints[0][6] && viewportWidth >= breakpoints[0][5]) {
    widthK = 6;
  } else if (viewportWidth < breakpoints[0][7] && viewportWidth >= breakpoints[0][6]) {
    widthK = 7;
  } else if (viewportWidth < breakpoints[0][8] && viewportWidth >= breakpoints[0][7]) {
    widthK = 8;
  } else {
    widthK = 9;
  }

  return [widthK, heightK];
}
import { formatDate } from "../utils/algorithms";

// Function to generate a file name from a string
const generateFileName = (str) => {
  let filename = str.toLowerCase();
  filename = filename.replace(/ /g, "_");
  
  filename = filename.replace(/[^a-zA-Z0-9-_]/g, '');

  return filename;
}

// makes a flashcard set into a .flsh language file
export const parseIntoFLSH = async (flashcardSet) => {
  if (typeof flashcardSet !== 'object') {
    return { error: "Input must be an object!", success: false, data: null };
  }

  let flashcardRawData = JSON.stringify(flashcardSet);
  let flashcardParams = `&PARAMS
  -> fileName>>"${generateFileName(flashcardSet.title)}.flsh";
  -> author>>"${flashcardSet.author}";
  -> dateGenerated>>"${formatDate(new Date(), true)}";
  -> clientBrowser>>"${navigator.userAgent}";
  -> encoding>>"utf-8";
  &END`;

  let allData = flashcardParams + flashcardRawData;
  let newDataRaw = await encryptString(allData);

  if (!newDataRaw.success) {
    return {
      error: newDataRaw.error,
      success: false,
      data: null
    }
  }

  let updatedStr = insertIVString(newDataRaw.data.iv, newDataRaw.data.encryptedData);
  updatedStr = insertKeyString(newDataRaw.data.key, updatedStr);

  return { error: null, success: true, data: {
    encryptedData: updatedStr,
    fileParams: parseFLSHparams(allData).data
  }};
}

// Function to parse the parameters of a .flsh file
export const parseFLSHparams = (flashcardParams) => {
  if (typeof flashcardParams !== 'string') {
    return { error: "Input must be a string!", success: false, data: null };
  }

  flashcardParams = flashcardParams.slice(0, flashcardParams.indexOf("&END") + 4).trim();

  let params = flashcardParams.split("\n").map(param => param.trim()).filter(param => param !== "&PARAMS" && param !== "&END")

  let fileParams = {};
  params.forEach(param => {
    let [key, value] = param.split(">>");
    key = key.trim().slice(3);
    value = value.trim().slice(1, -2);
    fileParams[key] = value;
  });

  return { error: null, success: true, data: fileParams };
}

// Function to read a .flsh decrypted file
export const parseFLSHintoObj = async (flshData) => {
  if (typeof flshData !== 'string') {
    return { error: "Input must be strings!", success: false, data: null };
  }
  
  // extract the IV and key
  let extractedData = extractIVandKey(flshData);

  let iv_asm = extractedData.data.iv;
  let key_asm = extractedData.data.key;

  let decryptedData = await decryptString(extractedData.data.cleansedData, key_asm, iv_asm);

  if (!decryptedData.success) {
    return {
      error: decryptedData.error,
      success: false,
      data: null
    }
  }

  let fileParams = parseFLSHparams(decryptedData.data).data;

  // read the flashcard data
  let flashcardData = decryptedData.data.split("&END")[1];
  let flashcardObj = JSON.parse(flashcardData);

  return { error: null, success: true, data: { 
    fileParams: fileParams, 
    data: flashcardObj 
  }};
}

// Function to generate a .flsh file
export const generateFlashFile = (fileName, content, downloadFile=true) => {
  const fileNameEx = `${fileName}`;

  if (typeof content !== 'string') {
    console.error("Content must be a string!");
    return { error: "Content must be a string!", success: false, data: null };
  }

  const file = new Blob([content], {type: 'text/plain'});

  if (downloadFile) {
    const url = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileNameEx;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }


  return { error: null, success: true, data: file };
}

// Function to encrypt a string
export const encryptString = async (text) => {
  if (typeof text !== 'string') {
    return { error: "Input must be a string!", success: false, data: null };
  }

  const key = await crypto.subtle.generateKey(
    { name: "AES-GCM", length: 256 },
    true,
    ["encrypt", "decrypt"]
  );

  const iv = crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
  const encodedText = new TextEncoder().encode(text);
  const encrypted = await crypto.subtle.encrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encodedText
  );

  const exportedKey = await crypto.subtle.exportKey("raw", key);

  return {
    error: null,
    success: true,
    data: {
      encryptedData: btoa(String.fromCharCode(...new Uint8Array(encrypted))),
      key: btoa(String.fromCharCode(...new Uint8Array(exportedKey))),
      iv: btoa(String.fromCharCode(...iv))
    }
  };
};

// Function to decrypt a string
export const decryptString = async (encryptedData, keyBase64, ivBase64) => {
  if (typeof encryptedData !== 'string' || typeof keyBase64 !== 'string' || typeof ivBase64 !== 'string') {
    return { error: "All inputs must be strings!", success: false, data: null };
  }

  const key = await crypto.subtle.importKey(
    "raw",
    Uint8Array.from(atob(keyBase64), c => c.charCodeAt(0)),
    { name: "AES-GCM" },
    true,
    ["decrypt"]
  );

  const iv = Uint8Array.from(atob(ivBase64), c => c.charCodeAt(0));
  const encryptedArray = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));

  try {
    const decrypted = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv: iv },
      key,
      encryptedArray
    );

    return {
      error: null,
      success: true,
      data: new TextDecoder().decode(decrypted)
    };
  } catch (e) {
    return { error: "Decryption failed!", success: false, data: null };
  }
};

// Function to add an IV to a string
export const insertIVString = (iv, mainString) => {
  let ivList = iv.split('');

  const spacing = Math.floor(Math.random() * 4) + 2;

  let result = `&${spacing}&${mainString}`;
  let mainIndex = 3; 

  for (let i = 0; i < ivList.length; i++) {
    if (mainIndex >= result.length) {
      result += '@'.repeat(spacing - 1) + ivList[i];

      if (i === ivList.length - 1) {
        result += '&&&';
      }
    } else {
      if (i === ivList.length - 1) {
        result = result.slice(0, mainIndex) + ivList[i] + '&&&' + result.slice(mainIndex);
        mainIndex += spacing + 1;
        break;
      } else {
        result = result.slice(0, mainIndex) + ivList[i] + result.slice(mainIndex);
        mainIndex += spacing + 1;
      }
    }
  }

  return result;
}

// Function to add a key to a string
export const insertKeyString = (key, mainString) => {
  if (mainString[0] !== '&') {
    return { error: "Insert the IV first!", success: false, data: null };
  }

  let keyList = key.split('');

  const spacing = parseInt(mainString[1]);
  const startIndex = mainString.indexOf('&&&') + 3;

  let result = mainString;
  let mainIndex = startIndex;

  for (let i = 0; i < keyList.length; i++) {
    if (mainIndex >= result.length) {
      result += '@'.repeat(spacing - 1) + keyList[i];

      if (i === keyList.length - 1) {
        result += '&&&&&';
      }
    } else {
      if (i === keyList.length - 1) {
        result = result.slice(0, mainIndex) + keyList[i] + '&&&&&' + result.slice(mainIndex);
        mainIndex += spacing + 1;
        break;
      } else {
        result = result.slice(0, mainIndex) + keyList[i] + result.slice(mainIndex);
        mainIndex += spacing + 1;
      }
    }
  }

  return result;
}

// Function to extract the IV and key from a string
export const extractIVandKey = (mainString) => {
  if (mainString[0] !== '&') {
    return { error: "Invalid '.flsh' file. Make sure it starts with '&'!", success: false, data: null };
  }

  let mainStringList = mainString.split('');

  let spacing = parseInt(mainString[1]);
  let globalIndx = 0;

  let ivStart = 3;
  let ivEnd = mainString.indexOf('&&&');
  let keyStart = mainString.indexOf('&&&') + 3;
  let keyEnd = mainString.indexOf('&&&&&');

  let containsIV = mainString.slice(ivStart, ivEnd);
  let containsKey = mainString.slice(keyStart, keyEnd);

  let iv = '';
  let key = '';

  globalIndx = ivStart;
  let index_count = -1;

  for (let i = 0; i < containsIV.length; i++) {
    if (i % (spacing + 1) === 0) {
      iv += containsIV[i];
      mainStringList = (mainStringList.join('').slice(0, (globalIndx - 1) - index_count) + mainStringList.join('').slice(globalIndx - index_count)).split('');
      index_count++;
    }
    globalIndx++;
  }

  globalIndx = keyStart;

  for (let k = 0; k < containsKey.length; k++) {
    if (k % (spacing + 1) === 0) {
      key += containsKey[k];
      mainStringList = (mainStringList.join('').slice(0, (globalIndx - 1) - index_count) + mainStringList.join('').slice(globalIndx - index_count)).split('');
      index_count++;
    }
    globalIndx++;
  }

  // remove the IV and key placeholders from the main string
  mainStringList = mainStringList.filter(char => char !== '&');
  mainStringList = mainStringList.filter(char => char !== '@');
  mainStringList = mainStringList.join('').slice(1).split('');

  let cleansedData = mainStringList.join('');

  return { error: null, success: true, data: { 
    iv: iv, 
    key: key,
    cleansedData: cleansedData
  }};
}


import React from 'react';
import './index.css';
import { route } from './utils/router';
import HomePage from './pages/Home-page/homePage';
import reportWebVitals from './reportWebVitals';
import { runBGChecker } from './scripts/backgroundChecker';

// import FlashCardView from './pages/Flashcard-view/flashCardView';
// import { flashcard } from './assets/exampleFlashcards/fromApp';

// // Render the home page
// route(
//   <FlashCardView data={flashcard} fillScreen />,
//   'strict'
// );

// Render the home page
route(
  <HomePage />,
  'strict'
);

runBGChecker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

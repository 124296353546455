import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderTree, faHome, faUsers } from "@fortawesome/free-solid-svg-icons";

// Import the styles
import "./Navbar.scss";

import { route } from "../../utils/router";
import { adjustOnScreenSize } from "../../utils/detectDevice";

import HomePage from "../../pages/Home-page/homePage";
import video from "../../assets/videos/sparkles.mp4";
import ProfilePage from "../../pages/profile-page/profilePage";
import FileView from "../../pages/Flash-card-File-Manager/FileView";

function Navbar() {
  const [hamVisible, setHamburgerVisible] = useState(false);

  function NavbarItem({ animDelay, faIcon, onClick=()=>{}, className, style={}, fontSize="20px" }) {
    return (
      <h1 className={`${className} nav-button`} style={{ ...style }} onClick={(e) => onClick(e)} anim-delay={animDelay}>
        <FontAwesomeIcon icon={faIcon} fontSize={fontSize} style={{ marginRight: '10px' }} />
      </h1>
    );
  }

  useEffect(() => {
    let hamSideBar = document.querySelector('.ham-side-menu');

    if (hamSideBar) {
      if (hamVisible) {
        hamSideBar.style.transform = 'translateX(0)';
      } else {
        hamSideBar.style.transform = 'translateX(75px)';
      }
    }

  }, [hamVisible]);

  return (
    <div className="navbar-main">
      <video 
        autoPlay 
        loop 
        muted 
        src={video} 
        className="navbar-background"
        controls={false}
        playsInline
      />
      <div className="navbar-item-1">
        <h1>Flashy</h1>
      </div>
      <div className="navbar-item-2">
        <NavbarItem  
          faIcon={faHome}                    
          onClick={() => { route(<HomePage />) }}
          className={`button-anim ${adjustOnScreenSize()[0] < 2 ? 'hidden' : 'move-in'}`}
          animDelay="1"
        />

        <NavbarItem 
          faIcon={faFolderTree} 
          onClick={() => { route(<FileView />) }} 
          className={`button-anim ${adjustOnScreenSize()[0] < 2 ? 'hidden' : 'move-in'}`}
          animDelay="2"
        />

        <NavbarItem 
          faIcon={faUsers} 
          onClick={() => { route(<ProfilePage/>) }}                
          className={`button-anim ${adjustOnScreenSize()[0] < 2 ? 'hidden' : 'move-in'}`}
          animDelay="3"
        />

      </div>
      <div className={`nav-hamburger ${adjustOnScreenSize()[0] >= 2 ? 'hidden' : 'move-in'}`}>
        <svg className="ham ham6" viewBox="0 0 100 100" width="80" onClick={(e) => {e.currentTarget.classList.toggle('ham-active'); setHamburgerVisible(!hamVisible)}}>
          <path
            className="line top"
            d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272" 
          />

          <path
            className="line middle"
            d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272" 
          />

          <path
            className="line bottom"
            d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272" 
          />
        </svg>

        <div className="ham-side-menu">
          <NavbarItem text="Home"              faIcon={faHome}       onClick={() => { route(<HomePage />) }} />
          <NavbarItem text="Flashcard Manager" faIcon={faFolderTree} onClick={() => { route(<FileView />) }} />
          <NavbarItem text="Profile"           faIcon={faUsers}      onClick={() => { route(<ProfilePage/>) }} />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
import React, { Suspense, useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faChevronRight, faEye, faEyeSlash, faFileCirclePlus, faFileImport, faFilePen, faFolder, faFolderPlus, faImages, faShareFromSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';

import Loader from '../../components/Loader/Loader';
import BackButton from '../../components/BackButton/BackButton';
import FlashCardView from '../Flashcard-view/flashCardView';

import './fileView.scss';

import { accentColor, secondaryColor } from '../../utils/projectColors';
import { listFlashcardsByUserID } from '../../scripts/databaseManager';
import { route } from '../../utils/router';
import { getCurrentUser, modifyUserSaveData, readUserSaveData } from '../../scripts/accountManager';
import NiceButton from '../../components/NiceButton/_layout';
import SimpleEditor from '../Flash-card-editor/flashCardEditorSimple';
import { isMobileDevice } from '../../utils/detectDevice';
import { parseFLSHintoObj } from '../../scripts/flashcardEncoder';
import { getTheme } from '../../utils/projectColors';

import { flashcard as flsh } from '../../assets/exampleFlashcards/fromApp';
import { deepCopy, nothing } from '../../utils/algorithms';
import { folder } from 'jszip';
import { NULL } from 'sass';

function FileView({ className }) {
  const [flashcardData, setFlashcardData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [nestedFiles, setNestedFiles] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [importOpen, setImportOpen] = useState(false);
  const [lastItem, setLastItem] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [folderOpenHistory, setFolderOpenHistory] = useState(null);
  const [mobileBarOpen, setMobileBarOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState(null);

  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Generate a recursive file structure from flashcards
  const generateRecursiveFileStructure = (flashcards) => {
    const files = [];

    const generateForOne = (flashcard, startIndex = 0) => {
      const path = flashcard.path.split('/');
      for (let i = 0; i < path.length; i++) {
        if (path[i] === '') {
          path.splice(i, 1);
          i--;
        }
      }
      
      path.unshift('/');
      path.push('');
      let currentPath = [];

      for (let index = 0; index < path.length; index++) {
        let folderName = path[index];

        if (index === startIndex) {
          if (index === path.length - 1) {
            currentPath.push({
              type: 'file',
              title: flashcard.title,
              data: flashcard,
              path: flashcard.path + `/${flashcard.title}.flsh`,
            })

            return currentPath;
          } else {
            currentPath.push({
              type: 'folder',
              title: folderName,
              data: generateForOne(flashcard, index + 1),
              path: path.slice(0, index + 1).join('/').slice(1) === ''? '/' : path.slice(0, index + 1).join('/').slice(1),
            })
          }
        } else {
        }
      }

      return currentPath;
    }

    const mergeFile = (files) => {
      const merged = [];

      files.forEach((file) => {
        if (file.type === 'file') {
          merged.push(file);
        } else if (file.type === 'folder'){
          let found = false;

          for (let mergedFile of merged) {
            if (mergedFile.type === 'folder' && mergedFile.title === file.title) {
              mergedFile.data.push(...file.data);
              found = true;
              break;
            }
          }

          if (!found) {
            merged.push(file);
          }
        }
      });

      return merged;
    }

    const mergeFiles = (files) => {
      if (files.length === 0) {
        return [];
      }

      for (let h = 0; h < files.length; h++) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].type === 'folder') {
            files[i].data = mergeFiles(files[i].data);
          }
        }
      }

      return mergeFile(files);
    }

    const sortByName = (files) => {
      return files.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    // merge and remove duplicate folders into one path
    const mergeDuplicates = (files) => {
      let merged = [];
      let mergedNames = new Set();
    
      const mergeRecursive = (file) => {
        if (!mergedNames.has(file.title)) {
          mergedNames.add(file.title);
    
          // Find all files with the same title
          const duplicates = files.filter(f => f.title === file.title && f !== file);
    
          // Merge data from duplicates
          if (Array.isArray(file.data)) {
            for (const duplicate of duplicates) {
              if (Array.isArray(duplicate.data)) {
                file.data.push(...duplicate.data);
              }
            }
            
            // Recursively merge duplicates in nested data
            file.data = mergeDuplicates(file.data);
          }
    
          merged.push(file);
        }
        return file;
      };
    
      files.forEach(mergeRecursive);
      return merged;
    };

    flashcards.forEach((flashcard) => {
      files.push(...generateForOne(flashcard));
    });

    let newFiles = mergeFiles(files);
    let merged = mergeDuplicates(newFiles);

    console.log("Loaded tree: ", merged);

    return merged;
  }

  // Handle what happens when a file is double clicked
  const handleFileClick = (event, fileData) => {
    route(<FlashCardView data={fileData.data} fillScreen previousComponent={<FileView />} />);
  }

  // Preview file component
  const PreviewFile = ({ selectedFile }) => {
    const recursiveCountOfFlashcards = (file, type="item") => {
      let count = 0;

      file.data.forEach((item) => {
        if (item.type === "folder") {
          count += recursiveCountOfFlashcards(item, type);
          if (type === "folder" || type === "item") {
            count++;
          }
        } else {
          if (type === "file" || type === "item") {
            count++;
          }
        }
      });

      return count;
    }

    if (selectedFile === null) {
      return (
        <div className='preview-main-cont'>
          <center>
            <div className='flashcard-group'>
              <div className="flashcard" />
              <div className="flashcard" />
              <div className="flashcard" />
            </div>
            <p>Select a file to preview</p>
          </center>
        </div>
      )
    }

    if (selectedFile.type === 'folder') {
      return (
        <div className='preview-main-cont' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '100%' }}>
          <FontAwesomeIcon icon={faFolder} style={{ fontSize: '10rem', marginBottom: '20px' }}/>
          <h2>{selectedFile.title}</h2>
          <hr style={{ width: '80%' }}/>
          <p>Subfolders in folder: {recursiveCountOfFlashcards(selectedFile, "folder")}</p>
          <p>Flashcards in folder: {recursiveCountOfFlashcards(selectedFile, "file")}</p>
        </div>
      )
    }

    return (
      <div className='preview-main-cont' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', maxWidth: '100%' }}>
        <h2>{selectedFile.title} - <i style={{ fontWeight: 'normal' }}>{selectedFile.data.author}</i></h2>
        <FlashCardView 
          data={selectedFile.data} 
          className="flash-view-preview" 
          showTopBar={false} 
          updateToCloud={false}
          cardFontSize='.5rem'    
          cardWidth='95%'
          useCardWidth
          sx={{
            height: '90%',
            width: 'calc(100% - 20px)'
          }} 
        />
      </div>
    )
  }

  // Recursively sort by alphabetical order with priority
  const sortAlphabetically = (nestedFiles, priority=null) => {
    const sort = (files) => {
      if (files.length === 0) {
        return [];
      }

      for (let h = 0; h < files.length; h++) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].type === 'folder') {
            files[i].data = sort(files[i].data);
          }
        }
      }

      const compareTitles = (a, b) => {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        if (titleA < titleB) {
          return -1;
        } else if (titleA > titleB) {
          return 1;
        } else {
          return 0;
        }
      };

      if (priority === 'folder') {
        files.sort((a, b) => {
          if (a.type === 'folder' && b.type === 'file') {
            return -1;
          } else if (a.type === 'file' && b.type === 'folder') {
            return 1;
          } else {
            return compareTitles(a, b);
          }
        });
      } else if (priority === 'file') {
        files.sort((a, b) => {
          if (a.type === 'file' && b.type === 'folder') {
            return -1;
          } else if (a.type === 'folder' && b.type === 'file') {
            return 1;
          } else {
            return compareTitles(a, b);
          }
        });
      } else {
        files.sort(compareTitles);
      }

      return files;
    }

    return sort(nestedFiles);
  }

  // get paths from history array
  const findPaths = (arr, activate=false) => {
    let pathsTo1 = new Map(); 
    let pathsToEmpty = new Map(); 
    let counter1 = 0; 
    let counterEmpty = 0; 

    if (activate) {
      console.log("Paths: ", arr);
      console.log("Paths regenerated: ", reconstructArray(findPaths(arr)));
    }

    const traverse = (arr, currentPath = []) => {
      for (let i = 0; i < arr.length; i++) {
        const current = arr[i];
        const newPath = [...currentPath, i];
        
        if (Array.isArray(current)) {
          if (current.length === 0) {
            // For empty array, store with unique identifier
            const key = `empty_${counterEmpty++}`;
            if (!pathsToEmpty.has(key) || newPath.length > pathsToEmpty.get(key).length) {
              pathsToEmpty.set(key, newPath);
            }
          } else {
            traverse(current, newPath);
          }
        } else if (current === 1) {
          // For value '1', store with unique identifier
          const key = `one_${counter1++}`;
          if (!pathsTo1.has(key) || newPath.length > pathsTo1.get(key).length) {
            pathsTo1.set(key, newPath);
          }
        }
      }
    };

    traverse(arr);

    // Combine all paths and sort by length (descending)
    const allPaths = [...pathsTo1.values(), ...pathsToEmpty.values()];
    return allPaths.sort((a, b) => b.length - a.length);
  };

  // convert paths into a single array history of paths
  const reconstructArray = (paths) => {
    const createNestedArray = (path, value) => {
      // Handle the base case
      if (path.length === 0) return value;
      
      // Create array of necessary size for current level
      const maxIndex = path[0];
      const arr = Array(maxIndex + 1).fill(0);
      
      // Recursively fill the next level
      arr[path[0]] = createNestedArray(path.slice(1), value);
      
      return arr;
    };

    const mergePaths = (arr1, arr2) => {
      if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
        // If either is 1 or [], prefer the non-zero value
        return arr1 === 0 ? arr2 : arr1;
      }
      
      const maxLength = Math.max(arr1.length, arr2.length);
      const result = Array(maxLength).fill(0);
      
      for (let i = 0; i < maxLength; i++) {
        if (i < arr1.length && i < arr2.length) {
          result[i] = mergePaths(arr1[i], arr2[i]);
        } else if (i < arr1.length) {
          result[i] = arr1[i];
        } else {
          result[i] = arr2[i];
        }
      }
      
      return result;
    };

    // Start with an empty array
    let result = [0];

    // Process each path
    paths.forEach(path => {
      const value = path.length === 3 && path[1] === 3 ? [] : 1; // If it's the empty array path
      const currentArray = [createNestedArray(path, value)];
      result = mergePaths(result, currentArray);
    });

    return result[0];
  };

  const addToPathHistory = (path, history) => {
    const paths = findPaths(history);
    const newPath = [...path];
    let found = false;

    for (let i = 0; i < paths.length; i++) {
      const currentPath = paths[i];
      if (currentPath.length === newPath.length) {
        let equal = true;
        for (let j = 0; j < currentPath.length; j++) {
          if (currentPath[j] !== newPath[j]) {
            equal = false;
            break;
          }
        }

        if (equal) {
          found = true;
          break;
        }
      }
    }

    if (!found) {
      paths.push(newPath);
    }

    setFolderOpenHistory(paths);

    return reconstructArray(paths);
  }

  const removeFromPathHistory = (path, history) => {
    const paths = findPaths(history);
    const newPath = [...path];
    let found = false;

    for (let i = 0; i < paths.length; i++) {
      const currentPath = paths[i];
      if (currentPath.length === newPath.length) {
        let equal = true;
        for (let j = 0; j < currentPath.length; j++) {
          if (currentPath[j] !== newPath[j]) {
            equal = false;
            break;
          }
        }

        if (equal) {
          paths.splice(i, 1);
          found = true;
          break;
        }
      }
    }

    if (found) {
      setFolderOpenHistory(paths);
    }

    return reconstructArray(paths);
  }

  const handleOpenMobilBar = (event) => {
    const mobileBar = document.querySelector('.mobile-bar');
    const bgBlur = document.querySelector('.bg-blur');

    setMobileBarOpen(!mobileBarOpen);

    if (mobileBar.classList.contains('menu-bar-out')) {
      mobileBar.classList.remove('menu-bar-out');
      mobileBar.classList.add('menu-bar-in');
      
      bgBlur.classList.remove('blur-out');
      bgBlur.classList.add('blur-in');
    } else {
      mobileBar.classList.remove('menu-bar-in');
      mobileBar.classList.add('menu-bar-out');

      bgBlur.classList.remove('blur-in');
      bgBlur.classList.add('blur-out');
    }
  }

  const handleShare = (_event) => {
    if (selectedItem === null) {
      toast.error('No flashcard selected');
      return;
    }

    if (selectedItem.type === 'folder') {
      toast.warning('Sharing for folders not implemented yet!');
      return;
    }

    let flashcardIDSelected = selectedItem.data.id;
    let url = `${window.location.origin}/flashcard/${flashcardIDSelected}`;

    navigator.clipboard.writeText(url).then(() => {
      toast.success('Link copied to clipboard');
    }).catch((error) => {
      console.error('Error copying to clipboard: ', error);
      toast.error('Error copying to clipboard');
    });
  }

  // check if the indexes array is equal to or all the items are the same as the history array with the same length
  const getFolderState = (indexes, history) => {
    console.log("Paths: ", history);

    for (let path of history) {
      if (indexes.length === path.length) {
        let equal = true;
        for (let i = 0; i < indexes.length; i++) {
          if (indexes[i] !== path[i]) {
            equal = false;
            break;
          }
        }

        if (equal) {
          return true;
        }
      } else {
        const newIndexes = [...indexes];
        for (let i = indexes.length; i < path.length; i++) {
          newIndexes.push('');
        }

        console.log('lists: ', newIndexes, path);

        let equal = true;
        for (let i = 0; i < newIndexes.length; i++) {
          if (newIndexes[i] !== path[i]) {
            if (newIndexes[i] !== '') {
              equal = false;
              break;
            }
          }
        }

        if (equal) {
          return true;
        }
      }
    }

    return false;
  };

  // Recursively load files
  const RecursivelyLoadFiles = ({ 
    className, 
    files, 
    keyCount = 0, 
    onItemDeselect = () => {}, 
    onFolderChange = () => {}, 
    onItemSelect = () => {}, 
    openHistory = [], 
    sx = {}, 
    lastItem = null,
    depth = 0,
    depthIndexes = [] 
  }) => {
    function Folder({ 
      file, 
      index = 0, 
      onClickFol = () => {}, 
      onItemSelectFol = () => {}, 
      onItemDeselectFol = () => {}, 
      onFolderChangeFol = () => {}, 
      folderHistory = openHistory, 
      divSx = {},
      depth = 0,
      depthIndexes = []
    }) {
      const initialState = getFolderState([...depthIndexes, index], folderHistory);
      console.log('Curr folder path: ', [...depthIndexes, index], initialState);
      const [faIcon, setFaIcon] = useState(initialState ? faChevronDown : faChevronRight);
      const [open, setOpen] = useState(initialState);
      let clickTimer = null;
  
      const handleFolderClick = (e, file) => {
        onClickFol(e, file);
        
        if (e.currentTarget.classList.contains('folder-open')) {
          onItemSelectFol(e, { file: file, index: index, type: file.type });
        } else {
          if (!e.target.classList.contains('chevron-change')) {
            onItemDeselectFol(e, { file: file, index: index, type: file.type });
          }
        }
      };
  
      const handleFolderExpand = (e, file) => {
        e.stopPropagation(); // Prevent event bubbling
        if (faIcon === faChevronRight) {
          setFaIcon(faChevronDown);
          onFolderChangeFol(e, { folder: file, index: index, state: 'open' });

          addToPathHistory([...depthIndexes, index], folderHistory);
        } else {
          setFaIcon(faChevronRight);
          onFolderChangeFol(e, { folder: file, index: index, state: 'closed' });

          addToPathHistory([...depthIndexes], folderHistory);
        }
        setOpen((prev) => !prev);
      };
  
      const handleClick = (e) => {
        if (clickTimer === null) {
          clickTimer = setTimeout(() => {
            clickTimer = null;
            // Single click action
            if (selectedPath !== file.path) {
              setSelectedPath(file.path);
              onItemSelectFol(e, { file: file, index: index, type: file.type });
            } else {
              setSelectedPath(null);
              onItemDeselectFol(e, { file: file, index: index, type: file.type });
            }
            onClickFol(e, file);
          }, 200); // Adjust this delay as needed
        } else {
          clearTimeout(clickTimer);
          clickTimer = null;
          // Double click action
          handleFolderExpand(e);
        }
      };
  
      return (
        <div className="folder-outer-cont" key={index} id={`${index};${file.path}`} path={file.path} style={{...divSx}} item-pos={[...depthIndexes, index].join('-')}>
          <div 
            className={`folder-cont ${selectedPath === file.path ? 'selected' : ''}`}
            onClick={handleClick}
            onDoubleClick={handleFolderExpand}
            id={`${index};${file.path}`} 
            path={file.path}
          >
            <FontAwesomeIcon 
              className="chevron-change" 
              icon={faIcon} 
              style={{ marginRight: '10px', height: 'stretch' }} 
              onClick={(e) => {
                e.stopPropagation();
                handleFolderExpand(e);
              }}
            />
            <FontAwesomeIcon icon={faFolder} style={{ marginRight: '10px' }}/>
            <p style={{ 
              userSelect: 'none',
              overflow: 'hidden',
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap'
            }}>
              {file.title}
            </p>
          </div>
          {open && (
            <RecursivelyLoadFiles 
              files={file.data} 
              className='file-manager-cont' 
              key={keyCount + 1} 
              onItemSelect={onItemSelect} 
              onItemDeselect={onItemDeselect} 
              onFolderChange={onFolderChange}
              openHistory={folderHistory}
              lastItem={lastItem}
              depth={depth + 1}
              depthIndexes={[...depthIndexes, index]}
            />
          )}
        </div>
      );
    }
  
    function File({ file, index, onDoubleClick=()=>{}, onClickFile=()=>{}, onFileSelectFile=()=>{}, onFileDeselectFile=()=>{}, divSx={} }) {
      let clickTimer = null;
      const handleClick = (e) => {
        if (clickTimer === null) {
          clickTimer = setTimeout(() => {
            clickTimer = null;
            // Single click action
            if (selectedPath !== file.path) {
              setSelectedPath(file.path);
              onFileSelectFile(e, { file: file, index: index, type: file.type });
            } else {
              setSelectedPath(null);
              onFileDeselectFile(e, { file: file, index: index, type: file.type });
            }
            onClickFile(e, file);
          }, 200); // Adjust this delay as needed
        } else {
          clearTimeout(clickTimer);
          clickTimer = null;
          // Double click action
          e.stopPropagation();
          onDoubleClick(e, file);
        }
      };
    
      return (
        <div 
          className={`file-cont ${selectedPath === file.path ? 'selected' : ''}`}
          key={index} 
          id={`${index};${file.path}`} 
          onClick={handleClick}
          path={file.path}
          style={{...divSx}}
          item-pos={`${index}-${depth}`}
        >
          <FontAwesomeIcon icon={faFilePen} style={{ marginRight: '10px' }}/>
          <p 
            style={{ 
              userSelect: 'none',
              overflow: 'hidden',
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap',
            }}
          >
            {file.title}
          </p>
        </div>
      );
    }
  
    if (lastItem === null) {
      return;
    }
  
    return (
      <div className={`files-main-cont ${className}`} key={keyCount} style={{...sx}}>
        {files.map((file, index) => {
          if (file.path === lastItem.path) {
            if (file.type === 'file') {
              return (
                <File 
                  file={file} 
                  index={index} 
                  key={index} 
                  onDoubleClick={(e) => handleFileClick(e, file)} 
                  onFileSelectFile={onItemSelect} 
                  onFileDeselectFile={onItemDeselect}
                  divSx={{
                    paddingBottom: '20px',
                  }}
                />
              );
            } else {
              return (
                <Folder 
                  file={file} 
                  index={index} 
                  key={index} 
                  onFolderChangeFol={onFolderChange} 
                  onItemSelectFol={onItemSelect} 
                  onItemDeselectFol={onItemDeselect}
                  folderHistory={openHistory}
                  divSx={{
                    paddingBottom: '20px',
                  }}
                  depth={depth}
                  depthIndexes={depthIndexes}
                />
              );
            }
          }
  
          if (file.type === 'file') {
            return (
              <File 
                file={file} 
                index={index} 
                key={index} 
                onDoubleClick={(e) => handleFileClick(e, file)} 
                onFileSelectFile={onItemSelect} 
                onFileDeselectFile={onItemDeselect}
              />
            );
          } else {
            return (
              <Folder 
                file={file} 
                index={index} 
                key={index} 
                onFolderChangeFol={onFolderChange} 
                onItemSelectFol={onItemSelect} 
                onItemDeselectFol={onItemDeselect}
                folderHistory={openHistory}
                depth={depth}
                depthIndexes={depthIndexes}
              />
            );
          }
        })}
      </div>
    );
  };

  // ===== FILE MANAGEMENT FUNCTIONS =====
  // TODO: fix search system
  const searchForItem = (path, onFound=()=>{}, files=nestedFiles) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].path === path) {
        onFound(files, files[i]);
        return files[i];

      } else if (files[i].type === 'folder') {
        searchForItem(path, onFound, files[i].data);

      } else {
        onFound(null);
        return null;
      }
    }
  }

  // Insert item into the nested files
  const insertItem = (item, path, onInsert=()=>{}, files=nestedFiles) => {
    for (let i = 0; i < files.length; i++) {
      if (files[i].path === path) {
        onInsert(files[i]);
        return files[i];

      } else if (files[i].type === 'folder') {
        searchForItem(path, onInsert, files[i].data);

      } else {
        onInsert(null);
        return null;
      }
    }
  }

  // Update item in the nested files
  const updateItem = (item, path) => {
    searchForItem(path, (files, foundItem) => {
      if (foundItem !== null) {
        foundItem = item;
      }
    });
  }

  // Delete item from the nested files
  const deleteItem = (path) => {
    searchForItem(path, (parentList, foundItem) => {
      if (foundItem !== null) {
        parentList.splice(parentList.indexOf(foundItem), 1);
      }
    })
  }

  // Handle file import
  const handleFileImport = async (file) => {
    if (file === null) {
      toast.error('No file selected');
      return Promise.reject('No file selected');
    }

    if (file.name.endsWith('.flsh')) {
      let reader = new FileReader();

      reader.onload = async (e) => {
        let data = e.target.result;

        parseFLSHintoObj(data).then((result) => {
          if (!result.success) {
            console.error('Invalid file decoding', result.error);
            toast.error('File Import failed');
            return Promise.reject('Invalid file decoding');
          }
  
          let actualFlashcard = result.data.data;
          route(<SimpleEditor 
            flashcardData={actualFlashcard} 
            previousComponent={<FileView />}
            editMode
          />, 'strict');

          toast.success('File imported successfully');
        });
      }

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        toast.error('File reading failed');
      };

      console.log("Starting to read the file as text");
      reader.readAsText(file);
    } else {
      console.error('File does not end with .flsh');
      toast.error('Invalid file type');
      return Promise.reject('Invalid file type');
    }
  } 

  const findLastItem = (files) => {
    let lastItem = null;
  
    const traverse = (items) => {
      for (let item of items) {
        if (item.type === 'folder') {
          if (item.data.length > 0) {
            traverse(item.data);
          }
        } else {
          lastItem = item;
        }
      }
    };
  
    try {
      if (Array.isArray(files)) {
        traverse(files);
      } else if (files.type === 'folder') {
        if (files.data.length > 0) {
          traverse(files.data);
        }
      } else {
        lastItem = files;
      }
    } catch (error) {
      console.error("Error traversing files:", error);
    }
  
    return lastItem;
  };

  const ImportPreview = ({ isShown, className, handleFileImport=()=>{} }) => {
    const [dragging, setDragging] = useState(false);
  
    useEffect(() => {
      const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
      };
  
      const handleDragLeave = (e) => {
        e.preventDefault();
        setDragging(false);
      };
  
      const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        const files = e.dataTransfer.files;
        if (files.length > 0 && files[0].name.endsWith('.flsh')) {
          handleFileImport(files[0]);
        }
      };
  
      const dropZone = document.querySelector('.import-box');
      if (dropZone) {
        dropZone.addEventListener('dragover', handleDragOver);
        dropZone.addEventListener('dragleave', handleDragLeave);
        dropZone.addEventListener('drop', handleDrop);
      }
  
      return () => {
        let dropZone = document.querySelector('.import-box');

        if (dropZone) {
          dropZone.removeEventListener('dragover', handleDragOver);
          dropZone.removeEventListener('dragleave', handleDragLeave);
          dropZone.removeEventListener('drop', handleDrop);
        }
      };
    }, []);
  
    if (isShown) {
      return (
        <div className={`import-preview-blur-cont ${className}`}>
          <div className='import-inner-box' style={{position: 'relative'}}>
            <NiceButton
              hasIcon
              icon={faXmark}
              onClick={() => setImportOpen(false)}
              sx={{ 
                position: 'absolute', 
                top: '10px', 
                left: '10px', 
                width: '30px', 
                height: '30px' 
              }}
            />
            <h2>Import file</h2>
            <hr />
            <p>Drag and drop a file here to import it or select it</p>
            <div className={`import-box ${dragging ? 'dragging' : ''}`}>
              <FontAwesomeIcon icon={faImages} style={{ fontSize: '135px', marginBottom: '20px' }} />
              <input type='file' accept='.flsh' onChange={(e) => handleFileImport(e.target.files[0])} />
            </div>
          </div>
        </div>
      );
    }
  
    return null;
  };

  useEffect(() => {
    // Fetch flashcards from database
    try {
      listFlashcardsByUserID(getCurrentUser().user.uid)
      .then((flashcards) => {
        console.log("Flashcards loaded!: ", flashcards);

        let flashcardsImport = flashcards;
  
        if (flashcardsImport === null) {
          flashcardsImport = [];
        }
  
        setFlashcardData(flashcardsImport);

        let generated = generateRecursiveFileStructure(flashcardsImport);
        
        setLastItem(findLastItem(generated));
        let sortedFiles = sortAlphabetically(generated, "folder");

        setNestedFiles(sortedFiles);
        setLoaded(true);
      })
      .catch((error) => {
        console.error('Error loading flashcards: ', error);
      });
    } catch (e) {
      // // ! TEMPORARY
      // let flashcardsImport = [flsh];

      // if (flashcardsImport === null) {
      //   flashcardsImport = [];
      // }

      // setFlashcardData(flashcardsImport);

      // setNestedFiles(generateRecursiveFileStructure(flashcardsImport))

      // setLoaded(true);

      toast.error('Error loading flashcards');
    }

    try {
      readUserSaveData('openFolderPaths', getCurrentUser().user.uid).then((res) => {
        if (res.status) {
          let result = findPaths(JSON.parse(res.data));
          setFolderOpenHistory(result);
        } else {
          setFolderOpenHistory([]);
        }
      });
    } catch (e) {
      console.error("Error loading folder history: ", e);

      toast.error('Error loading folder history');
    }
  }, []);

  // useEffect(() => {
  //   if (nestedFiles !== null) {
  //     searchForItem('/Romantika na SLO', (foundItem) => {
  //       console.log("Found item: ", foundItem);
  //     });
  //   }
  // }, [nestedFiles]);

  if (!loaded && nestedFiles === null && folderOpenHistory === null) {
    return <Loader primaryColor={accentColor} scale={2} />;
  }

  if (folderOpenHistory === null) {
    return <Loader primaryColor={accentColor} scale={2} />;
  }

  return (
    <Suspense fallback={<Loader primaryColor={accentColor} scale={2} />}>
      <div className={`file-view-main ${className}`}>
        <main>
          <div className='bg-blur' />
          <div className='top-bar'>
            <BackButton 
              sx={{ position:'unset', marginLeft: '10px' }}
              beforeBack={() => {
                modifyUserSaveData('openFolderPaths', JSON.stringify(reconstructArray(folderOpenHistory)), getCurrentUser().user.uid).then((res) => {
                  if (res.status) {
                    // toast.success('Folder history saved');
                  } else {
                    toast.error('Error saving folder history');
                  }
                })
              }}
            />
            {isMobileDevice()?
              <div className='mobile-bar menu-bar-out' style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'column',
                position: 'absolute',
                top: '0',
                right: '0',
                zIndex: '100',
                height: 'auto',
                marginTop: '5px',
                marginRight: '5px',
                borderRadius: '20px', 
                backgroundColor: mobileBarOpen? '#003687': 'transparent',
                boxSizing: 'border-box',
              }}>
                <NiceButton
                  icon={faBars}
                  hasIcon
                  sx={{
                    margin: '10px',
                    zIndex: '101 !important'
                  }}
                  onClick={(e) => handleOpenMobilBar(e)}
                />

                <NiceButton
                  icon={faFileCirclePlus}
                  hasIcon
                  sx={{
                    margin: '10px'
                  }}
                  onClick={(e) => route(<SimpleEditor previousComponent={<FileView />}/>, 'strict')} 
                />

                <NiceButton
                  hasIcon
                  icon={faFileImport}
                  sx={{
                    margin: '10px'
                  }}
                  onClick={(e) => setImportOpen(true)}
                />

                <NiceButton
                  hasIcon
                  icon={previewOpen? faEyeSlash : faEye}
                  sx={{
                    margin: '10px'
                  }}
                  onClick={(e) => setPreviewOpen((prev) => !prev)}
                />

                <NiceButton
                  hasIcon
                  icon={faShareFromSquare}
                  sx={{
                    margin: '10px'
                  }}
                  onClick={(e) => handleShare(e)}
                />
              </div>
              :
              <>
                <NiceButton 
                  onClick={(e) => route(<SimpleEditor previousComponent={<FileView />}/>, 'strict')} 
                  hasIcon
                  icon={faFileCirclePlus}
                />
                {/* TODO: Add the new file and folder into a dropdown
                <NiceButton
                  onClick={() => {}}
                  hasIcon
                  icon={faFilePen}
                  sx={{ marginRight: '10px' }}
                /> */}
                <NiceButton
                  onClick={(e) => setImportOpen(true)}
                  hasIcon
                  icon={faFileImport}
                />
                <NiceButton
                  onClick={() => {
                    setPreviewOpen((prev) => !prev);
                  }}
                  hasIcon
                  icon={previewOpen? faEyeSlash : faEye}
                />
                <NiceButton
                  onClick={(e) => handleShare(e)}
                  hasIcon
                  icon={faShareFromSquare}
                  className={'nice-btn-last'}
                />
              </>
            }
          </div>
          <div className='main-cont-files'>
            <RecursivelyLoadFiles 
              files={nestedFiles} 
              lastItem={lastItem}
              openHistory={folderOpenHistory}
              className='file-manager-cont root-dir' 
              onItemSelect={(target, e) => {
                //TODO: Implement shift and ctrl select
                setSelectedItem(e.file);
              }}
              onFolderChange={(target, e) => {
                console.log("Folder expanded -> : ", e);
              }}
              onItemDeselect={(target, e) => {
                setSelectedItem(null);
              }}
              sx={{
                width: '100%'
              }}
            />

            {!previewOpen? null :
              // isMobileDevice() || windowSize[0] <= 1250? null : 
                <PreviewFile 
                  selectedFile={selectedItem}
                />
            }
          </div>
          <ImportPreview isShown={importOpen} handleFileImport={(e) => handleFileImport(e)} />
        </main>

        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}          newestOnTop={false}
          pauseOnHover={false}
          closeOnClick
          rtl={false}
          theme={getTheme()}
        />
      </div>
    </Suspense>
  );
}

export default FileView;

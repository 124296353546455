import { auth, firestore } from "../config/firebaseConfig.js";
import { 
  GoogleAuthProvider, 
  updateProfile, 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword, 
  sendPasswordResetEmail, 
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup
} from "firebase/auth";
import {
  collection,
  addDoc,
  getDocs,
  setDoc,
  query,
  where,
  getDoc
} from "firebase/firestore";


import image from '../assets/images/Default-pfp.png'

import { convertLinkToImgSrc, convertDivToImgSrc, scaleImage, convertToWebP } from '../utils/algorithms';

// set the default user data template
const defaultUserData = (data, check=false) => {
  let template = {
    displayName: data.displayName? data.displayName : data.username? data.username : null,
    email: data.email? data.email : null,
    password: data.password? data.password : null,
    photoURL: data.photoURL? data.photoURL : image,
    uid: data.uid? data.uid : null,
    provider: data.providerData? data.providerData[0].providerId : null,
    savedUserData: data.savedUserData? data.savedUserData : {
      filter: "none",
      openFolderPaths: "[]",
    },
    dateJoined:  data.dateJoined? data.dateJoined : data.metadata.creationTime? data.metadata.creationTime : null,
  };

  if (!check) {
    return {result:template, key:null, success:null};
  } else {
    let keys = Object.keys(template);
    for (let key of keys) {
      if (template[key] === null) {
        return {result:null, success: false, key:key};
      }
    }
    return {result:null, success: true, key:null};
  }
}

// get the document reference by uid
async function getDocumentReferenceByUid(uid) {
  // Reference to the collection
  const usersCollection = collection(firestore, 'users'); // Replace 'users' with your collection name

  // Create a query against the collection
  const q = query(usersCollection, where('uid', '==', uid));

  // Execute the query
  const querySnapshot = await getDocs(q);

  // Check if any documents were found
  if (!querySnapshot.empty) {
    // Get the first document reference (assuming uid is unique)
    const docRef = querySnapshot.docs[0].ref;
    console.log('Document reference:', docRef);
    return docRef;
  } else {
    console.log('No document found with the specified uid.');
    return null;
  }
}

// Set the data of a stored user in firestore
const setUserData = async (data, uid) => {
  try {
    const docRef = await getDocumentReferenceByUid(uid);
    await setDoc(docRef, data);

    return {result: data, success: true, error: null};

  } catch (error) {
    console.error("Error setting user data: ", error);
    return {result: null, success: false, error: error};
  }
}

// modify a key from the user data
const modifyUserData = async (data, modifiedData, uid) => {
  try {
    let newData = {
      ...data,
      ...modifiedData
    }

    try {
      await setUserData(newData, uid);
    } catch (error) {
      console.error("Error saving modified user data: ", error);
      return {result: null, success: false, error: error};
    }

    return {result: newData, success: true, error: null};
  } catch (error) {
    console.error("Error modifying user data: ", error);
    return {result: null, success: false, error: error};
  }
}

// Sign in with email and password
async function signInEmail(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);

    return { user: userCredential.user, success: true, error: null, signInError: null };

  } catch (error) {
    console.error("Error signing in with email: ", error);

    let errorMessage = "An error occurred during sign-in.";
    let sError = null;

    if (error.code === 'auth/user-not-found') {
      errorMessage = "No user found with this email.";
      sError = "user-not-found";

    } else if (error.code === 'auth/wrong-password') {
      errorMessage = "Incorrect password.";
      sError = "password";
    } else if (error.code === 'auth/invalid-credential') {
      errorMessage = "Invalid email or password.";
      sError = "invalid-credential";
    }
    return { success: false, user: null, error: errorMessage, signInError: sError };
  }
}

// Sign out
async function signOut() {
  try {
    let user = getCurrentUser().user;

    await auth.signOut();
    return {status: true, user: user};

  } catch (error) {
    console.error("Error signing out: ", error);
    let user = getCurrentUser().user;

    return {status: false, user: user};
  }
}

// Sign up with email, password and username
async function signUpEmail(email, password, username) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);

    let rawData = {
      password: password,
      username: username,
      photoURL: await scaleImage(await convertDivToImgSrc(username.charAt(0).toUpperCase()))
    }

    let user = defaultUserData({...userCredential.user, ...rawData});
    
    try {
      // save to firestore
      await addDoc(collection(firestore, "users"), user.result);

      return {success: true, user: user.result, error: null};
    } catch (error) {
      console.error("Error saving user data to firestore: ", error);
      return {success: false, user: null, error: null};
    }

  } catch (error) {
    console.error("Error signing up with email: ", error);
    return {success: false, user: null, error: error};
  }
}

// Reset password
async function resetPassword(email) {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;

  } catch (error) {
    console.error("Error resetting password: ", error);
    return {status: false, user: null};
  }
}

// Modify user save data
async function modifyUserSaveData(key, data, uid) {
  try {
    const docRef = await getDocumentReferenceByUid(uid);
    await setDoc(docRef, { savedUserData: { [key]: data } }, { merge: true });

    return {status: true, error: null};
  } catch (error) {
    console.error("Error modifying user save data: ", error);

    return {
      status: false,
      error: error
    }
  }
}

// read user save data
async function readUserSaveData(key, uid) {
  try {
    const docRef = await getDocumentReferenceByUid(uid);
    const doc = await getDoc(docRef);

    if (doc.exists()) {
      return {status: true, data: doc.data().savedUserData[key], error: null};
    } else {
      return {status: false, data: null, error: "No document found."};

    }
  } catch (error) {
    console.error("Error reading user save data: ", error);

    return {
      status: false,
      data: null,
      error: error
    }
  }
}

// read all user save data
async function readAllUserSaveData(uid) {
  try {
    const docRef = await getDocumentReferenceByUid(uid);
    const doc = await getDoc(docRef);

    if (doc.exists()) {
      return {status: true, data: doc.data().savedUserData, error: null};
    } else {
      return {status: false, data: null, error: "No document found."};
    }
  } catch (error) {
    console.error("Error reading all user save data: ", error);

    return {
      status: false,
      data: null,
      error: error
    }
  }
}

// Signs in with Google using a popup
async function signInWithGooglePopup() {
  try {
    const provider = new GoogleAuthProvider();
    const popup = signInWithPopup(auth, provider);

    if (!popup) {
      console.error("Popup was blocked. Please allow popups for this site.");
      return {status: false, user: null, error: "popup blocked"};
    }

    const result = await popup;

    let userRaw = result.user;
    let user = defaultUserData(result.user);
    user.password = '';

    // Handle profile picture
    if (userRaw.photoURL) {
      if (userRaw.photoURL.startsWith('http')) {
        const tempImg = await convertLinkToImgSrc(userRaw.photoURL);
        user.result.photoURL = await scaleImage(tempImg);
      }
    } else {
      const firstLetter = userRaw.displayName ? userRaw.displayName[0] : 'U';
      const tempImg = await convertDivToImgSrc(firstLetter);
      user.result.photoURL = await scaleImage(tempImg);
    }

    try {
      let userCheck = await checkUserExists(userRaw.email);

      console.log("User check: ", userCheck);

      if (userCheck.status === false) {
        // save to firestore
        await addDoc(collection(firestore, "users"), user.result);

        return {status: true, user: user.result, error: null};
      } else {
        console.log("User already exists in firestore.");
        modifyUserData(user.result, defaultUserData(userRaw, true).result, userRaw.uid);

        return {status: true, user: user.result, error: null};
      }
    } catch (error) {
      console.error("Error saving user data to firestore: ", error);
      return {status: false, user: null, error: error};
    }

  } catch (error) {
    console.error("Error signing in with Google: ", error);

    if (error.code === 'auth/popup-blocked') {
      return {status: false, user: null, error: "popup blocked"};
    } else if (error.code === 'auth/unauthorized-domain') {
      return {status: false, user: null, error: "popup blocked"};
    } else {
      return {status: false, user: null, error: error};
    }
  }
}

// Signs in with Google using a redirect
async function signInWithGoogleRedirect() {
  try {
    const provider = new GoogleAuthProvider();
    await signInWithRedirect(auth, provider);

    console.log("Redirecting to Google sign in.");

    const result = await getRedirectResult(auth);

    console.log("Redirect result: ", result);

    if (!result) {
      console.error("No redirect result found.");
      return { status: false, user: null, error: "no redirect result" };
    }
    
    let userRaw = result.user;
    let user = defaultUserData(result.user);
    user.password = '';

    // Handle profile picture
    if (userRaw.photoURL) {
      if (userRaw.photoURL.startsWith('http')) {
        const tempImg = await convertLinkToImgSrc(userRaw.photoURL);
        user.result.photoURL = await scaleImage(tempImg);
      }
    } else {
      const firstLetter = userRaw.displayName ? userRaw.displayName[0] : 'U';
      const tempImg = await convertDivToImgSrc(firstLetter);
      user.result.photoURL = await scaleImage(tempImg);
    }
    
    try {
      console.log("Checking if user exists with email: ", userRaw.email);
      let userCheck = await checkUserExists(userRaw.email);
    
      console.log("User check result: ", userCheck);
    
      if (userCheck.status === false) {
        console.log("User does not exist. Adding to Firestore.");
        // save to firestore
        await addDoc(collection(firestore, "users"), user.result);
    
        console.log("User added to Firestore successfully.");
        return { status: true, user: user.result, error: null };
      } else {
        console.log("User already exists in Firestore.");
        modifyUserData(user.result, defaultUserData(userRaw, true).result, userRaw.uid);
    
        console.log("User data modified successfully.");
        return { status: true, user: user.result, error: null };
      }
    } catch (error) {
      console.error("Error saving user data to Firestore: ", error);
      return { status: false, user: null, error: error };
    }
    
  } catch (error) {
    console.error("Error signing in with Google: ", error);
  
    if (error.code === 'auth/unauthorized-domain') {
      return { status: false, user: null, error: "unauthorized domain" };
    } else {
      return { status: false, user: null, error: error };
    }
  }
}

// Initiate Google sign in with redirect
export async function initiateGoogleSignInRedirect() {
  try {
    const provider = new GoogleAuthProvider();
    await signInWithRedirect(auth, provider);
    console.log("Redirecting to Google sign in.");
  } catch (error) {
    console.error("Error initiating Google sign in redirect: ", error);
  }
}

// Handle Google sign in redirect result
export async function handleGoogleSignInRedirectResult() {
  try {
    const result = await getRedirectResult(auth);
    console.log("Redirect result: ", result);

    if (!result) {
      console.log("No redirect result found.");
      return { status: false, user: null, error: "no redirect result" };
    }

    let userRaw = result.user;
    let user = defaultUserData(result.user);
    user.password = '';

    // Handle profile picture
    if (userRaw.photoURL) {
      if (userRaw.photoURL.startsWith('http')) {
        const tempImg = await convertLinkToImgSrc(userRaw.photoURL);
        user.result.photoURL = await scaleImage(tempImg);
      }
    } else {
      const firstLetter = userRaw.displayName ? userRaw.displayName[0] : 'U';
      const tempImg = await convertDivToImgSrc(firstLetter);
      user.result.photoURL = await scaleImage(tempImg);
    }

    try {
      console.log("Checking if user exists with email: ", userRaw.email);
      let userCheck = await checkUserExists(userRaw.email);

      console.log("User check result: ", userCheck);

      if (userCheck.status === false) {
        console.log("User does not exist. Adding to Firestore.");
        // save to firestore
        await addDoc(collection(firestore, "users"), user.result);

        console.log("User added to Firestore successfully.");
        return { status: true, user: user.result, error: null };
      } else {
        console.log("User already exists in Firestore.");
        modifyUserData(user.result, defaultUserData(userRaw, true).result, userRaw.uid);

        console.log("User data modified successfully.");
        return { status: true, user: user.result, error: null };
      }
    } catch (error) {
      console.error("Error saving user data to Firestore: ", error);
      return { status: false, user: null, error: error.message };
    }

  } catch (error) {
    console.error("Error handling Google sign in redirect result: ", error);

    if (error.code === 'auth/unauthorized-domain') {
      return { status: false, user: null, error: "unauthorized domain" };
    } else {
      return { status: false, user: null, error: error.message };
    }
  }
}

// Change username
async function changeUsername(username) {
  try {
    if (typeof username !== 'string' || username.length < 3) {
      throw new Error('Username must be a string with at least 3 characters.');
    }
    const user = auth.currentUser;
    if (user) {
      await user.updateProfile({ displayName: username });
      return {status: true, user: user};
    } else {
      console.error("Current user is null.");
      return {status: false, user: null};
    }
  } catch (error) {
    console.error("Error changing username: ", error);
    return {status: false, user: null};
  }
}

// Change profile picture
async function changeProfilePicture(src) {
  try {
    const user = auth.currentUser;

    // // convert image to webp and make the resolution to 200 x 200
    // let srcN = await scaleImage(src);
    // let res = await convertToWebP(srcN);

    // if (res.success) {
    //   srcN = res.result;
    // } else {
    //   return {
    //     status: false,
    //     user: null
    //   }
    // }

    await updateProfile(user, { photoURL: src });
    return {status: true, user: user};

  } catch (error) {
    console.error("Error changing profile picture: ", error);
    return {status: false, user: null};
  }
}

// get the current signed in user
function getCurrentUser() {
  try {
    if (auth.currentUser === null) {
      return {status: false, user: null};
    } else {
      return {status: true, user: auth.currentUser};
    }

  } catch (error) {
    console.error("Error getting current user: ", error);
    return {status: false, user: null};
  }
}

// get the userData by id
async function getUserDataID(uid) {
  try {
    const userSnapshot = await getDocs(collection(firestore, "users"));
    let dbUser = userSnapshot.docs.map(doc => doc.data()).filter((user) => user.uid === uid)[0];
    let localUser = null;

    if (dbUser.uid === getCurrentUser().user.uid) {
      localUser = getCurrentUser().user;
    } else {
      localUser = {};
    }

    let userNew = {
      ...localUser,
      ...dbUser
    }

    return {status: true, user: userNew};

  } catch (error) {
    console.error("Error getting user data from ID: ", error);
    return {status: false, user: null};
  }
}

// list all user data
async function listUsers() {
  try {
    const usersSnapshot = await getDocs(collection(firestore, "users"));
    const users = usersSnapshot.docs.map(doc => doc.data());

    return {status: true, users: users};

  } catch (error) {
    console.error("Error listing all users data: ", error);
    return {status: false, users: null};
  }
}

// Check if user already exists in firestore
async function checkUserExists(email) {
  try {
    const users = await listUsers();

    for (let user of users.users) {
      if (user.email === email) {
        return {status: true, user: user, error: null};
      }
    }

    return {status: false, user: null, error: null};

  } catch (error) {
    console.error("Error checking if user exists: ", error);
    return {status: false, user: null, error: error};
  }
}

handleGoogleSignInRedirectResult();

export { signInEmail, signOut, signUpEmail, resetPassword, signInWithGooglePopup, 
  changeUsername, changeProfilePicture, getCurrentUser, getUserDataID, listUsers,
  signInWithGoogleRedirect, setUserData, modifyUserData, checkUserExists, modifyUserSaveData,
  readAllUserSaveData, readUserSaveData 
};
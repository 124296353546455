/*
	This file was generated by Text to FlashCard converter
	Author: Julian Loy - 2024
*/

import { formatDate } from "../../utils/algorithms";

export const getAllFlashcards = () => {
	// Flashcard: "Fjodor M. Dostojevski" with description: "o avtorju: Fjodor Mihailovič Dostojevski"
	const flashcards1 = {
		title: 'Fjodor M. Dostojevski',
		description: 'o avtorju: Fjodor Mihailovič Dostojevski',
		author: "Julian Loy",
		authorUID: "871TGrS63UaiosBMfNvgPIyeAZ12",
		cardSorting: false,
		date: formatDate(new Date(), false),
		id: "",
		path: "/realizem/avtorji",
		lastOpened: formatDate(new Date(), true),
		shuffleCards: false,
		progress: 0,
		timesViewed: 0,
		cards: [
			{
				"front": `Kdaj in kje se je rodil?`,
				"back": `1821 v Moskivi`
			},
			{
				"front": `Kdaj in kje je umrl?`,
				"back": `umrl je leta 1881 v Peterburgu`
			},
			{
				"front": `kje se je šolal?`,
				"back": `v vojaški šoli v Peterburgu`
			},
			{
				"front": `kaj se je z njemu zgodilo leta 1849?`,
				"back": `obsojen je bil na smrt zaradi tega, kej je sodeloval v revolucionalnem krožku`
			},
			{
				"front": `Kako se je rešil smrtne obsodbe?`,
				"back": `potegoval se je z carjem`
			},
			{
				"front": `Kaj je moral delati za kazen?`,
				"back": `4 leta prisiljenega dela v Sibiriji`
			},
			{
				"front": `kakšno je bilo njegovo družinsko stanje?`,
				"back": `2-krat poro;en z 4 otroci`
			},
			{
				"front": `Kaj je bila njegova obsecija?`,
				"back": `igranje iger na srečo 🗿🗿`
			},
			{
				"front": `kam beži zaradi njgovih dologv?`,
				"back": `beži v evropo`
			},
			{
				"front": `Kje je bil njegov zadnji dom in zakaj je umrl?`,
				"back": `zadnji dom v Peterburgu, umrl je zaradi epileptičnega napada`
			},
			{
				"front": `Kam ga uvrščamo glede na njegova dela?`,
				"back": `uvrščamo ga v psihološki realizem`
			},
		]
	};


	// Flashcard: "Hčere Svet" with description: "France Prešern - Hčere svet"
	const flashcards2 = {
		title: 'Hčere Svet',
		description: 'France Prešern - Hčere svet',
		author: "Julian Loy",
		authorUID: "871TGrS63UaiosBMfNvgPIyeAZ12",
		cardSorting: false,
		date: formatDate(new Date(), false),
		id: "",
		path: "/Romantika na SLO/Prešern",
		lastOpened: formatDate(new Date(), true),
		shuffleCards: false,
		progress: 0,
		timesViewed: 0,
		cards: [
			{
				"front": `Kdaj je nastalo to delo?`,
				"back": `leta 1829`
			},
			{
				"front": `Kdaj je bilo prvič objavleno?`,
				"back": `1831 v Kranjski Čbelci`
			},
			{
				"front": `Katera literarna vrsta je to delo?`,
				"back": `Romanca`
			},
			{
				"front": `kaj je posebnost tega dela?`,
				"back": `Da je 1. slovenska romanca`
			},
			{
				"front": `kaj so motivi tega dela?`,
				"back": `spor med očetom in hčer`
			},
			{
				"front": `kaj je tema?`,
				"back": `ljubezenska, družinska`
			},
			{
				"front": `kaj je ideja?`,
				"back": `Ljubezen je svobodna in presega pričakovanja okolice`
			},
		]
	};


	// Flashcard: "Pevcu" with description: "France Prešern - Pevcu"
	const flashcards3 = {
		title: 'Pevcu',
		description: 'France Prešern - Pevcu',
		author: "Julian Loy",
		authorUID: "871TGrS63UaiosBMfNvgPIyeAZ12",
		cardSorting: false,
		date: formatDate(new Date(), false),
		id: "",
		path: "/Romantika na SLO/Prešern",
		lastOpened: formatDate(new Date(), true),
		shuffleCards: false,
		progress: 0,
		timesViewed: 0,
		cards: [
			{
				"front": `Kdaj je nastalo delo?`,
				"back": `leta 1838`
			},
			{
				"front": `V kateero zbirko se je objavilo delo in katerega leta?`,
				"back": `leta 1846 v delo "Poezije"`
			},
			{
				"front": `kaj je tema dela?`,
				"back": `pesniška oz. poetološka, bivanjska oz. eksistencialna`
			},
			{
				"front": `Kaj je stopica?`,
				"back": `amfibrah`
			},
			{
				"front": `Kakšna je rima?`,
				"back": `zaporedna`
			},
			{
				"front": `kaj je vokalno branje?`,
				"back": `spreminjanje vokalov na naglasu besed glede na kitico`
			},
			{
				"front": `Kakšen je vrstni red teh vokalov v vokalnem branju?`,
				"back": `a, e, i, o, u`
			},
			{
				"front": `Kaj je posebnost v tem delu?`,
				"back": `vsebuje retorična vprašanja ter apokrife`
			},
			{
				"front": `Kaj je apokrif?`,
				"back": `knjiga biblijske tematike`
			},
		]
	};


	// Flashcard: "Slovo od mladosti" with description: "France Prešern - Slovo od mladosti"
	const flashcards4 = {
		title: 'Slovo od mladosti',
		description: 'France Prešern - Slovo od mladosti',
		author: "Julian Loy",
		authorUID: "871TGrS63UaiosBMfNvgPIyeAZ12",
		cardSorting: false,
		date: formatDate(new Date(), false),
		id: "",
		path: "/Romantika na SLO/Prešern",
		lastOpened: formatDate(new Date(), true),
		shuffleCards: false,
		progress: 0,
		timesViewed: 0,
		cards: [
			{
				"front": `Kdaj je nastalo to delo?`,
				"back": `leta 1829`
			},
			{
				"front": `Kdaj in kje je bilo prvič objavljeno?`,
				"back": `leat 1830 v Kranjski Čbelci`
			},
			{
				"front": `Kaj je posebnost te pesmi?`,
				"back": `To je prva Prešernova romantična pesem`
			},
			{
				"front": `Kaj so motivi tega dela?`,
				"back": `motiv svetobolja, vdanosti v usodo, razočaranja nad življenjem`
			},
			{
				"front": `Kaj so teme tega dela?`,
				"back": `bivanjska, družbenokritična`
			},
			{
				"front": `Kaj je ideja tega dela?`,
				"back": `svet je pozabil na vrednote, vodi ga samo materialne vrednote, laži itd.`
			},
			{
				"front": `Kaj je literarna vrsta tega dela?`,
				"back": `elegija oz. Žalostinka`
			},
			{
				"front": `Kakšna je kitica?`,
				"back": `Stanca ali oktava`
			},
		]
	};


	// Flashcard: "Soneti nesreče" with description: "France Prešern - Soneti nesreče"
	const flashcards5 = {
		title: 'Soneti nesreče',
		description: 'France Prešern - Soneti nesreče',
		author: "Julian Loy",
		authorUID: "871TGrS63UaiosBMfNvgPIyeAZ12",
		cardSorting: false,
		date: formatDate(new Date(), false),
		id: "",
		path: "/Romantika na SLO/Prešern",
		lastOpened: formatDate(new Date(), true),
		shuffleCards: false,
		progress: 0,
		timesViewed: 0,
		cards: [
			{
				"front": `kdaj in kje je bilo napisano to delo?`,
				"back": `leta 1832 v Celovcu`
			},
			{
				"front": `Kdaj in kje je bilo prvič objavljeno?`,
				"back": `v Kranjski Čbelici 4 leta 1834`
			},
			{
				"front": `Kaj je bilo s Prešernom v tem času?`,
				"back": `Bil je v osebni krizi zaradi odvetniškega izpita...`
			},
			{
				"front": `Kaj je značilost tega dela?`,
				"back": `- prvotno 7 sonetov, na koncu jih je samo 6
- je _cikel_ sonetov`
			},
			{
				"front": `Povej vsebino sonetov?`,
				"back": `1. O Vrba - sonet domotožja
2. Popotnik pride - sonet spoznanja
3. Hrast, ki Vihar - sonet poraženosti
4. Komur je sreče - sonet usode
5. življenje ječe - sonet smrti
6. čez tebe več ne bo - sonet ravnodušnosti`
			},
		]
	};


	// Flashcard: "Sonetni venec" with description: "France Prešern - Sonetni venec"
	const flashcards6 = {
		title: 'Sonetni venec',
		description: 'France Prešern - Sonetni venec',
		author: "Julian Loy",
		authorUID: "871TGrS63UaiosBMfNvgPIyeAZ12",
		cardSorting: false,
		date: formatDate(new Date(), false),
		id: "",
		path: "/Romantika na SLO/Prešern",
		lastOpened: formatDate(new Date(), true),
		shuffleCards: false,
		progress: 0,
		timesViewed: 0,
		cards: [
			{
				"front": `kdaj je delo bilo prvič objavljeno?`,
				"back": `leta 1834 v časopisu Illiriches Blatt`
			},
			{
				"front": `Kdaj je delo nastalo?`,
				"back": `na god primiceve Julije`
			},
			{
				"front": `iz česa je sestavljen sonetni venec?`,
				"back": `Je cikel 14 Sonetov + dodatni sonet Magistrale`
			},
			{
				"front": `Kaj je značilno pri tem ciklu sonettov?`,
				"back": `akrostih "PRIMICOVI JULJI"`
			},
		]
	};


	// Flashcard: "Zločin in kazen" with description: "Fjodor Mihailovič Dostojevski - Zločin in kazen"
	const flashcards7 = {
		title: 'Zločin in kazen',
		description: 'Fjodor Mihailovič Dostojevski - Zločin in kazen',
		author: "Julian Loy",
		authorUID: "871TGrS63UaiosBMfNvgPIyeAZ12",
		cardSorting: false,
		date: formatDate(new Date(), false),
		id: "",
		path: "/",
		lastOpened: formatDate(new Date(), true),
		shuffleCards: false,
		progress: 0,
		timesViewed: 0,
		cards: [
			{
				"front": `Kje je izšel ta roman?`,
				"back": `leta 1866 v reviji _Ruski vestnik_`
			},
			{
				"front": `kaj je snov Zločina in kazni?`,
				"back": `roman Pijanec (_Marmeladov_)`
			},
			{
				"front": `Kaj naredi z idejo zgodbe?`,
				"back": `združi jo z idejo romana Pijanec in idejo zločina, umora`
			},
		]
	};


	return [ 
		flashcards1,
		flashcards2,
		flashcards3,
		flashcards4,
		flashcards5,
		flashcards6,
		flashcards7 
	];
};
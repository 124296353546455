const quickSort = (arr) => {
  if (arr.length <= 1) return arr;

  const pivot = arr[arr.length - 1];
  const left = [];
  const right = [];

  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i][0] < pivot[0]) {
      left.push(arr[i]);
    } else {
      right.push(arr[i]);
    }
  }

  return [...quickSort(left), pivot, ...quickSort(right)];
};

// Function to parse the custom date format "MM/DD/YYYY-HH:MM:SS"
const parseCustomDate = (dateString) => {
  const [datePart, timePart] = dateString.split('-');
  const [month, day, year] = datePart.split('/');
  const [hours, minutes, seconds] = timePart.split(':');
  return new Date(year, month - 1, day, hours, minutes, seconds);
};

// Grades the flashcards based on the date difference in seconds
const gradeFlashcards = (flashcards) => {
  let gradedFlashcards = [];

  const now = new Date();

  for (let i = 0; i < flashcards.length; i++) {
    const lastDate = parseCustomDate(flashcards[i].lastOpened);
    const timeDifference = (now - lastDate) / 1000; // Difference in seconds
    gradedFlashcards.push([timeDifference, flashcards[i]]);
  }

  let sorted = quickSort(gradedFlashcards);
  return sorted.map((card) => card[1]);
}

export { gradeFlashcards, quickSort };
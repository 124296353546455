import React, { Suspense, useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { faEye, faEyeSlash, faGears, faSave, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@mui/material';
import { Dropdown, MenuItem as BaseMenuItem, Menu, MenuButton as BaseMenuButton } from '@mui/base';
import { menuItemClasses } from '@mui/material/MenuItem';
import { styled } from '@mui/system';

import BackButton from '../../components/BackButton/BackButton';
import Loader from '../../components/Loader/Loader';
import SignIn from '../sign-in/signIn';
import NiceButton from '../../components/NiceButton/_layout';
import HomePage from '../Home-page/homePage';

import { accentColor, getTheme } from '../../utils/projectColors';
import { nothing, validateCredential } from '../../utils/algorithms';
import { changeProfilePicture, getCurrentUser, getUserDataID, modifyUserSaveData, signOut } from '../../scripts/accountManager';
import { route } from '../../utils/router';

import './profilePage.scss';

function ProfilePage({ className }) {
  const [userData, setUserData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [curProfilePic, setCurProfilePic] = useState(getCurrentUser().user.photoURL);
  const fileInputRef = useRef(null);
  const [filter, setFilter] = useState('none');
  const menuRef = useRef(null);

  // TODO: Make profile page nice and make it work
  // TODO: Make it responsive

  useEffect(() => {
    let userSignedIn = getCurrentUser().status;

    console.log("User signed in: ", userSignedIn, getCurrentUser().user);

    if (userSignedIn) {
      let userData = getCurrentUser().user;
      
      getUserDataID(userData.uid).then((data) => {
        console.log("User data: ", data);
        setUserData(data.user);
        setCurProfilePic(data.user.photoURL);
        setFilter(data.user.savedUserData.filter);

        setLoaded(true);
      });
    } else {
      route(<SignIn />);
    }
  }, []);

  // If the user is not signed in, return the sign in page
  if (!loaded) {
    return <Loader primaryColor={accentColor} scale={2} />;
  }

  // User stats component
  const UserStats = () => {
    const [passwordShown, setPasswordShown] = useState(false);

    return (
      <div className='usr-stats'>
        <img src={curProfilePic} alt='Profile' />
        <div>
          <h2><span style={{ fontWeight: 'normal' }}>username:</span> {userData.displayName}</h2>
          <h2><span style={{ fontWeight: 'normal' }}>email:</span> {userData.email}</h2>
          <div style={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <h2>
              <span style={{ fontWeight: 'normal' }}>password:</span> {
                userData.password === null? 
                  `none ('${userData.provider}')`
                  :
                  passwordShown? 
                    userData.password 
                    : 
                    '*'.repeat(userData.password.length)
                }
            </h2>
            <div 
              style={{ 
                marginLeft: '10px', 
                cursor: 'pointer', 
                height: '100%', 
                display: userData.password === null? 
                  'none' 
                  : 
                  'flex', 
                alignItems: 'center', 
                justifyContent: 'center' 
              }}
            >
              <FontAwesomeIcon 
                icon={passwordShown? faEyeSlash : faEye} 
                onClick={() => setPasswordShown((prev) => !prev)} 
              />
            </div>
          </div>
          <div>
            <h4>Joined: {userData.metadata.creationTime}</h4>
          </div>
        </div>
      </div>
    );
  }

  // A separator component
  const Separator = ({ className, sepDistance='10px', lineStyle={}, boxStyle={} }) => {
    return (
      <div className={`separator ${className}`} style={{ ...boxStyle }}>
        <div className='line' style={{ marginLeft: sepDistance, marginRight: sepDistance, ...lineStyle }}></div>
      </div>
    );
  }

  const handleSaveChanges = async () => {
    let result = [];

    const password = document.querySelector('input[type="password"]').value;
    const username = document.querySelector('input[type="text"]').value;

    // const email = document.querySelector('input[type="email"]').value;

    // TODO: Finish this function

    if (curProfilePic !== userData.photoURL) {
      let usrPfp = curProfilePic;
      let res = await changeProfilePicture(usrPfp);

      if (res.status) {
        nothing();
      } else {
        toast.error('Error changing profile picture!');
        return {
          success: false,
          error: 'profile picture change error'
        }
      }

      // ^ change profile picture in DB
      result.push('profile picture');
    }

    if (username !== '' && username !== userData.displayName && username !== null) {
      // ^ change username in DB
      result.push('username');
    }

    if (userData.provider !== 'google.com' 
      && password !== '' 
      && password !== null
      && password !== userData.password
    ) {
      let validateResult = validateCredential('password', password);
      if (validateResult.success) {
        // ^ change password in DB
        result.push('password');
      } else {
        return {
          success: false,
          error: validateResult.error
        }
      }
    } else if (userData.provider === 'google.com' && password !== '') {
      toast.error('You cannot change your password if you signed in with Google!');
      return {
        success: false,
        error: 'google.com account password change error'
      }
    }

    // if (userData.provider !== 'google.com' 
    //   && email !== '' 
    //   && email !== null
    //   && email !== userData.email
    // ) {
    //   let validateResult = validateCredential('email', email);
    //   if (validateResult.success) {
    //     // ^ change email in DB
    //     result.push('email');
    //   } else {
    //     return {
    //       success: false,
    //       error: validateResult.error
    //     }
    //   }
    // } else if (userData.provider === 'google.com' && email !== '') {
    //   toast.error('You cannot change your email if you signed in with Google!');
    //   return {
    //     success: false,
    //     error: 'google.com account email change error'
    //   }
    // }

    if (filter !== userData.savedUserData.filter) {
      await modifyUserSaveData('filter', filter, userData.uid);
      result.push('filter');
    }

    if (result.length > 0) {
      return {
        success: true,
        error: null
      }
    } else {
      return {
        success: false,
        error: 'no changes made'
      }
    }
  }

  // A user profile changing component
  const UserProfileSettings = ({ className, fileInputRef }) => {
    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

    useEffect(() => {
      let img = document.querySelector('.usr-stats img');
      img.style.filter = filter === 'none'? '' : `${filter}`;
    }, [filter]);

    let defaultButtonSx = {
      '& .MuiInputBase-input': {
        color: 'rgba(255, 255, 255, .7)', 
      },
      '& .MuiInputLabel-root': {
        color: 'rgba(255, 255, 255, .7)', 
      },
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: 'rgba(255, 255, 255, 1)', 
        },
        '& fieldset': {
          borderColor: 'rgba(255, 255, 255, .7)', 
        },
        '&:hover fieldset': {
          borderColor: 'rgba(255, 255, 255, 1)', 
        },
      }
    }

    let pfpFilters = [
      "grayscale&grayscale(1)", 
      "sepia&sepia(1)", 
      "blur&blur(5px)", 
      "brightness&brightness(0.5)", 
      "contrast&contrast(200%)", 
      "hue-rotate&hue-rotate(90deg)", 
      "invert&invert(1)", 
      "saturate&saturate(8)",
      "none&none"
    ];

    const blue = {
      50: '#F0F7FF',
      100: '#C2E0FF',
      200: '#99CCF3',
      300: '#66B2FF',
      400: '#3399FF',
      500: '#007FFF',
      600: '#0072E6',
      700: '#0059B3',
      800: '#004C99',
      900: '#003A75',
    };
    
    const grey = {
      50: '#F3F6F9',
      100: '#E5EAF2',
      200: '#DAE2ED',
      300: '#C7D0DD',
      400: '#B0B8C4',
      500: '#9DA8B7',
      600: '#6B7A90',
      700: '#434D5B',
      800: '#303740',
      900: '#1C2025',
    };
    
    const Listbox = styled('ul')(
      ({ theme }) => `
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      box-sizing: border-box;
      padding: 6px;
      margin: 12px 0;
      min-width: 200px;
      border-radius: 12px;
      overflow: auto;
      outline: 0;
      background: ${getTheme() === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${getTheme() === 'dark' ? grey[700] : grey[200]};
      color: ${getTheme() === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${
        getTheme() === 'dark' ? 'rgba(0,0,0, 0.50)' : 'rgba(0,0,0, 0.05)'
      };
      z-index: 1;
      `,
    );
    
    const MenuItem = styled(BaseMenuItem)(
      ({ theme }) => `
      list-style: none;
      padding: 8px;
      border-radius: 8px;
      cursor: default;
      user-select: none;
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &:focus {
        outline: 3px solid ${getTheme() === 'dark' ? blue[600] : blue[200]};
        background-color: ${getTheme() === 'dark' ? grey[800] : grey[100]};
        color: ${getTheme() === 'dark' ? grey[300] : grey[900]};
      }
    
      &.${menuItemClasses.disabled} {
        color: ${getTheme() === 'dark' ? grey[700] : grey[400]};
      }
      `,
    );
    
    const MenuButton = styled(BaseMenuButton)(
      ({ theme }) => `
      font-family: 'IBM Plex Sans', sans-serif;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.5;
      padding: 8px 16px;
      border-radius: 8px;
      color: white;
      transition: all 150ms ease;
      cursor: pointer;
      background: ${getTheme() === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${getTheme() === 'dark' ? grey[700] : grey[200]};
      color: ${getTheme() === 'dark' ? grey[200] : grey[900]};
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    
      &:hover {
        background: ${getTheme() === 'dark' ? grey[800] : grey[50]};
        border-color: ${getTheme() === 'dark' ? grey[600] : grey[300]};
      }
    
      &:active {
        background: ${getTheme() === 'dark' ? grey[700] : grey[100]};
      }
    
      &:focus-visible {
        box-shadow: 0 0 0 4px ${getTheme() === 'dark' ? blue[300] : blue[200]};
        outline: none;
      }
      `,
    );

    return (
      <div className={`user-config ${className}`}>
        <h2>Profile settings</h2>
        <div>
          <div className='profile-settings-left'>
            <h3>Change profile picture</h3>
            <NiceButton 
              variant='contained' 
              component='label'
              useChildren
              width='150px'
              height='40px'
              onClick={() => handleButtonClick()}
            >
              Upload Picture
              <input 
                type='file' 
                hidden 
                accept='.jpeg, .png, .jpg, .webp, .PNG, .JPG, .JPEG, .WEBP' 
                ref={fileInputRef}
                onChange={(e) => setCurProfilePic(URL.createObjectURL(e.target.files[0]))}
              />
            </NiceButton>
            <Separator sepDistance='0px' lineStyle={{ width: '100%' }} />
            <h4>User pfp filters</h4>
            <Dropdown>
              <NiceButton 
                useChildren 
                sx={{ width:"150px", height: "40px" }}
                onClick={() => menuRef.current.click()}
              >
                Filters
              </NiceButton>
              <MenuButton ref={menuRef} sx={{ 
                marginLeft: '-90px', 
                backgroundColor: 'transparent', 
                color: 'transparent',
                border: 'none',
              }}/>
              <Menu slots={{ listbox: Listbox }}>
                {pfpFilters.map((filter, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => setFilter(filter.split('&')[1])}
                  >
                    {filter.split('&')[0]}
                  </MenuItem>
                ))}
              </Menu>
            </Dropdown>
          </div>
          <div className='profile-settings-right'>
            <h3>Change username</h3>
            <TextField
              variant='outlined'
              label="username"
              title='Username'
              type='text'
              fullWidth
              sx={{
                ...defaultButtonSx,
              }}
            />
            {/* <h3>Change email</h3>
            <TextField
              variant='outlined'
              label="email"
              title='Email'
              type='email'
              fullWidth
              sx={{
                ...defaultButtonSx,
              }}
            /> */}
            <h3>Change password</h3>
            <TextField
              variant='outlined'
              label="password"
              title='Password'
              type='password'
              fullWidth
              sx={{
                ...defaultButtonSx,
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  // Handle sign out
  const handleSignOut = () => {
    signOut().then((res) => {
      if (res.status) {
        toast.success('Signed out successfully!');
        setTimeout(() => {
          route(<SignIn />);
        }, 800);
      } else {
        toast.error('Error signing out!');
      }
    });
  }

  return (
    <Suspense fallback={<Loader primaryColor={accentColor} scale={2} />}>
      <div className={`profile-page-main ${className}`}>
        <div className='top-buttons on-top'>
          <BackButton />
          <div style={{ display: 'flex' }}>
            <NiceButton 
              onClick={() => {}} 
              useChildren 
              className='sign-out-btn on-top' 
            >
              <FontAwesomeIcon icon={faGears} />
            </NiceButton>
            <NiceButton 
              onClick={() => handleSignOut()} 
              useChildren 
              className='sign-out-btn on-top' 
              width='130px' 
            >
              <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '10px' }} />
              Sign Out
            </NiceButton>
          </div>
        </div>
        <main>
          <div className='container cont-profile-stats'>
            <UserStats />
            <Separator />
            <UserProfileSettings fileInputRef={fileInputRef} />
            <Separator />
            <NiceButton
              onClick={() => handleSaveChanges().then((res) => {
                let dontError = [
                  "google.com account email change error",
                  "google.com account password change error"
                ]

                if (res.success) {
                  toast.success('Changes saved successfully!');

                  setTimeout(() => {
                    route(<HomePage />);
                  }, 800);
                } else {
                  if (!dontError.includes(res.error)) {
                    toast.error(res.error);
                  }
                }
              })}
              useChildren
              width='40%'
              sx={{
                margin: '10px',
                minWidth: '150px',
                maxWidth: '400px',
              }}
            >
              Save Changes
              <FontAwesomeIcon icon={faSave} style={{ marginLeft: '10px' }} fontSize='1.2rem'/>
            </NiceButton>
          </div>
        </main>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          pauseOnHover={false}
          closeOnClick
          rtl={false}
          theme={getTheme()}
        />
      </div>
    </Suspense>
  );
}

export default ProfilePage;
import { collection, getDocs, addDoc, deleteDoc, getDoc, onSnapshot } from "firebase/firestore";
import { firestore, doc, setDoc } from "../config/firebaseConfig.js";
import { formatDate, jsxToString, parseToJSX } from "../utils/algorithms";
import { getAllFlashcards } from '../assets/exampleFlashcards/flashcards.js';
import { uploadFile } from "./storageManager";
import { query, where } from "firebase/firestore";
import { flashcard } from "../assets/exampleFlashcards/fromApp.js";
import { getDocsFromCache, getDocsFromServer, getDocFromCache } from "firebase/firestore";

// TODO: minimize read/write operations to the database over the entire app

const CACHE_KEY = 'flashcard_cache_v1';
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Add localStorage availability check
const isLocalStorageAvailable = () => {
  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};

const cache = loadCacheFromStorage() || {
  flashcards: null,
  lastUpdated: null,
  documents: new Map(),
  CACHE_DURATION,
  collectionLastModified: null,
  idLookup: new Map(),
  userFlashcards: new Map(),
  userLastUpdated: new Map(),
};

const dbState = {
  lastWriteTime: null,
  lastModifiedId: null,
  isListening: false
};

function setupDatabaseListener() {
  if (dbState.isListening) return; // Prevent multiple listeners

  const flashcardsRef = collection(firestore, "flashcards");
  
  // Listen only for metadata changes
  const unsubscribe = onSnapshot(flashcardsRef, { includeMetadataChanges: true }, 
    (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        // Only track actual modifications, not initial load
        if (change.type === 'modified' && 
            !snapshot.metadata.hasPendingWrites && 
            !snapshot.metadata.fromCache) {
          dbState.lastWriteTime = Date.now();
          dbState.lastModifiedId = change.doc.id;
          console.log(`Document ${change.doc.id} modified at ${new Date(dbState.lastWriteTime)}`);
          
          // Invalidate cache for the modified document
          if (cache.documents.has(change.doc.id)) {
            cache.documents.delete(change.doc.id);
          }
        }
      });
    },
    (error) => {
      console.error("Error setting up database listener:", error);
    }
  );

  dbState.isListening = true;
  console.log("Database listener set up");
  return unsubscribe;
}

setupDatabaseListener();

function isDocumentFresh(id) {
  return dbState.lastModifiedId !== id || 
         (cache.documents.has(id) && 
          cache.documents.get(id).timestamp > dbState.lastWriteTime);
}

// Add these new functions for cache persistence
function loadCacheFromStorage() {
  if (!isLocalStorageAvailable()) {
    console.log('localStorage not available, using memory-only cache');
    return null;
  }

  try {
    const savedCache = localStorage.getItem(CACHE_KEY);
    if (savedCache) {
      const parsed = JSON.parse(savedCache);
      // Convert JSON objects back to Maps
      parsed.documents = new Map(Object.entries(parsed.documents || {}));
      parsed.idLookup = new Map(Object.entries(parsed.idLookup || {}));
      parsed.userFlashcards = new Map(Object.entries(parsed.userFlashcards || {}));
      parsed.userLastUpdated = new Map(Object.entries(parsed.userLastUpdated || {}));
      return parsed;
    }
  } catch (e) {
    console.error('Error loading cache:', e);
  }
  return null;
}

function saveCacheToStorage() {
  if (!isLocalStorageAvailable()) {
    return; // Skip saving if localStorage isn't available
  }

  try {
    const cacheToSave = {
      ...cache,
      documents: Object.fromEntries(cache.documents),
      idLookup: Object.fromEntries(cache.idLookup),
      userFlashcards: Object.fromEntries(cache.userFlashcards),
      userLastUpdated: Object.fromEntries(cache.userLastUpdated),
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheToSave));
  } catch (e) {
    console.error('Error saving cache:', e);
  }
}

// Read a flashcard by its firebase ID
async function getFlashcardByID(id) {
  try {
    const now = Date.now();
    
    // If document exists in cache and hasn't been modified since last cache
    if (cache.documents.has(id) && 
        (!dbState.lastModifiedId || 
         dbState.lastModifiedId !== id || 
         cache.documents.get(id).timestamp > dbState.lastWriteTime)) {
      const cachedDoc = cache.documents.get(id);
      if (now - cachedDoc.timestamp < cache.CACHE_DURATION) {
        console.log('Document retrieved from memory cache');
        return cachedDoc.data;
      }
    }

    const docRef = doc(firestore, 'flashcards', id);
    
    // Try to get from Firestore cache first
    try {
      const docSnap = await getDocFromCache(docRef);
      // Only use cached version if document hasn't been modified
      if (docSnap.exists() && 
          (!dbState.lastModifiedId || 
           dbState.lastModifiedId !== id || 
           docSnap.metadata.fromCache)) {
        console.log('Document retrieved from Firestore cache');
        const result = {
          error: null,
          success: true,
          data: docSnap.data()
        };
        
        cache.documents.set(id, {
          data: result,
          timestamp: now
        });
        
        return result;
      }
      // If document was modified, fall through to server fetch
    } catch (e) {
      // Cache miss, continue to server fetch
    }

    // Only fetch from server if necessary
    console.log('Fetching fresh data from server');
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const result = {
        error: null,
        success: true,
        data: docSnap.data()
      };
      
      cache.documents.set(id, {
        data: result,
        timestamp: now
      });
      
      return result;
    }
    
    return {
      error: "No such document exists.",
      success: false,
      data: null
    };
  } catch (error) {
    console.error("Error getting flashcard by ID: ", error);
    return {
      error: error,
      success: false,
      data: null
    };
  }
}

// Read all flashcards from the database
async function listFlashcards() {
  try {
    const now = Date.now();
    
    // Return cached data if no modifications have occurred
    if (cache.flashcards && 
        cache.lastUpdated && 
        (!dbState.lastWriteTime || cache.lastUpdated > dbState.lastWriteTime)) {
      console.log('Returning collection from memory cache');
      return cache.flashcards;
    }

    const flashcards = [];
    const flashNew = [];
    const flashcardsRef = collection(firestore, "flashcards");

    // Try to get from Firestore cache first
    try {
      const snapshot = await getDocsFromCache(flashcardsRef);
      // Only use cached version if no modifications or cache is newer than modifications
      if (!dbState.lastWriteTime || 
          (snapshot.metadata.fromCache && cache.lastUpdated > dbState.lastWriteTime)) {
        console.log("Data retrieved from Firestore cache");
        snapshot.forEach((doc) => {
          flashcards.push({
            ...doc.data(),
            id: doc.id,
          });
        });
      } else {
        throw new Error('Cache outdated'); // Force server fetch
      }
    } catch (e) {
      // If cache fails or is outdated, get from server
      const snapshot = await getDocsFromServer(flashcardsRef);
      snapshot.forEach((doc) => {
        flashcards.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      console.log("Fetched data from server");
    }

    for (let flashcard of flashcards) {
      flashcard = parseToJSX(flashcard);
      flashNew.push(flashcard);
    }

    // Update cache
    cache.flashcards = flashNew;
    cache.lastUpdated = now;
    saveCacheToStorage(); // Save to localStorage

    return flashNew;
  } catch (error) {
    console.error("Error listing flashcards: ", error);
  }
}

// Read all flashcards from a specific user from the database
async function listFlashcardsByUserID(uid) {
  try {
    const now = Date.now();
    
    // Check persistent cache first
    if (cache.userFlashcards.has(uid) && 
        cache.userLastUpdated.has(uid)) {
      const lastUpdate = cache.userLastUpdated.get(uid);
      const isExpired = now - lastUpdate > CACHE_DURATION;
      
      if (!isExpired && (!dbState.lastWriteTime || lastUpdate > dbState.lastWriteTime)) {
        console.log('Returning user flashcards from persistent cache');
        return cache.userFlashcards.get(uid);
      }
    }

    const flashcards = [];
    const flashNew = [];
    const flashcardCollection = collection(firestore, 'flashcards');
    const q = query(flashcardCollection, where('authorUID', '==', uid));

    // Try Firestore cache first
    try {
      const snapshot = await getDocsFromCache(q);
      
      // Check if cache is valid (not empty and not outdated)
      if (!snapshot.empty && 
          (!dbState.lastWriteTime || 
           snapshot.metadata.fromCache)) {
        console.log("Using Firestore cache data");
        snapshot.forEach((doc) => {
          flashcards.push({
            ...doc.data(),
            id: doc.id,
          });
        });
      } else {
        throw new Error('Cache empty or outdated');
      }
    } catch (e) {
      // If cache fails or is outdated, get from server
      console.log("Getting fresh data from server");
      const snapshot = await getDocs(q);  // Using getDocs instead of getDocsFromServer
      
      snapshot.forEach((doc) => {
        flashcards.push({
          ...doc.data(),
          id: doc.id,
        });
      });
    }

    if (flashcards.length > 0) {
      for (let flashcard of flashcards) {
        flashcard = parseToJSX(flashcard);
        flashNew.push(flashcard);
      }

      // Update user-specific cache
      cache.userFlashcards.set(uid, flashNew);
      cache.userLastUpdated.set(uid, now);
      saveCacheToStorage(); // Save to localStorage

      console.log(`Found ${flashcards.length} flashcards for user ${uid}`);
      return flashNew;
    }

    console.log('No flashcards found for user:', uid);
    return [];
  } catch (error) {
    console.error("Error listing flashcards: ", error);
    return [];
  }
}

// Modify a field of a flashcard
export const modifyFlashcardField = async (id, field, value) => {
  try {
    const docRef = doc(firestore, 'flashcards', id);
    await setDoc(docRef, { [field]: value }, { merge: true });

    return { error: null, success: true };
  } catch (error) {
    console.error("Error modifying flashcard field: ", error);
    return { error: error, success: false };
  }
} 

// Save the flashcard set to the database
async function saveFlashcardSet(flashcardSet, id=null) {
  try {
    let docRef;

    if (flashcardSet.cards.length === 0) {
      console.error("Error adding flashcard set: ", "No cards in the set.");
      return;
    }

    for (let k = 0; k < flashcardSet.cards.length; k++) {
      if (flashcardSet.cards[k] === "end") {
        flashcardSet.cards.splice(k, 1);
      }
    }

    flashcardSet = jsxToString(flashcardSet); // convert jsx to string 

    if (id === null || flashcardSet.id === "") {
      docRef = await addDoc(collection(firestore, "flashcards"), flashcardSet);

    } else {
      docRef = await setDoc(doc(firestore, "flashcards", id), flashcardSet);
    }

    invalidateCache(); // Clear cache when new data is added
    return docRef;
  } catch (error) {
    console.error("Error adding flashcard set: ", error);
  }
}

// Get the id of the flashcard set
const getIdFromFlashcard = async (flashcard) => {
  // Create a unique key for the flashcard based on its properties
  const makeKey = (card) => `${card.title}-${card.author}-${card.authorUID}`;
  const cardKey = makeKey(flashcard);
  
  // Check if we have this card's ID cached
  if (cache.idLookup.has(cardKey)) {
    console.log('Flashcard ID retrieved from cache');
    return cache.idLookup.get(cardKey);
  }
  
  // If we have all flashcards cached already, use those instead of querying
  if (cache.flashcards) {
    const found = cache.flashcards.find(card => 
      card.title === flashcard.title && 
      card.author === flashcard.author && 
      card.authorUID === flashcard.authorUID
    );
    
    if (found) {
      cache.idLookup.set(cardKey, found.id);
      return found.id;
    }
  }

  // If not found in cache, query specifically for this flashcard
  try {
    const flashcardsRef = collection(firestore, "flashcards");
    const q = query(flashcardsRef, 
      where("title", "==", flashcard.title),
      where("author", "==", flashcard.author),
      where("authorUID", "==", flashcard.authorUID)
    );
    
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const id = querySnapshot.docs[0].id;
      cache.idLookup.set(cardKey, id);
      saveCacheToStorage(); // Save to localStorage
      return id;
    }
    
    return null;
  } catch (error) {
    console.error("Error getting flashcard ID: ", error);
    return null;
  }
}

// Remove the flashcard set from the database
const removeFlashcardSet = async (id) => {
  try {
    const docRef = doc(firestore, 'flashcards', id);
    await deleteDoc(docRef);

    return { error: null, success: true };
  } catch (error) {
    console.error("Error removing flashcard set: ", error);
    return { error: error, success: false };
  }
}

// 11, 8, 6, 2, 1

function generateFilesFromDictionaries(dictList) {
  return dictList.map((dict, index) => {
    const jsonString = JSON.stringify(dict);
    const file = new File([jsonString], `file${index}.flsh`, { type: 'application/json' });
    return file;
  });
}

const flashes = getAllFlashcards();

for (let i = 0; i < flashes.length; i++) {
  if (i !== 6) {
    // saveFlashcardSet(flashes[i]);
  }
}

// TODO: Lower the firebase writes amount

// saveFlashcardSet(flashes[5], "I0SusV8ZbN7V3erlBqN4");

// saveFlashcardSet(flash, "D6w5BCbMk9Sfa8pp3E4Z")

// var collections = await listFlashcards();
// var flashcards = generateFilesFromDictionaries(collections);

// for (let flashcard of flashcards) {
//   uploadFile(flashcard).then((fileUrls) => {
//     console.log('Uploaded file URLs:', fileUrls);
//   });
// }

function invalidateCache() {
  cache.flashcards = null;
  cache.lastUpdated = null;
  cache.documents.clear();
  cache.idLookup.clear();
  cache.userFlashcards.clear();
  cache.userLastUpdated.clear();
  
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(CACHE_KEY);
  }
}

export { listFlashcards, saveFlashcardSet, getIdFromFlashcard, removeFlashcardSet, generateFilesFromDictionaries, listFlashcardsByUserID, getFlashcardByID, invalidateCache };
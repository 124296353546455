// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "flash-card-app-ab38e.firebaseapp.com",
  projectId: "flash-card-app-ab38e",
  storageBucket: "flash-card-app-ab38e.appspot.com",
  messagingSenderId: "142112379823",
  appId: "1:142112379823:web:efcdb95959b3fda12ed55a",
  measurementId: "G-8Q0BWZ9H7Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { firestore, doc, setDoc, auth, analytics, storage, app, firebaseConfig };
import ReactDOM from 'react-dom/client';
import React from 'react';

// Create ReactDOM root instance
var root = false;

if (!root) {
  root = ReactDOM.createRoot(document.getElementById('root'));

  console.log('Root is: ', root);
}

/* ALL OF THESE ARE DEPRECATED
// History object to manage navigation
const history = {
  listeners: [],
  push(path) {
    window.history.pushState({}, '', path);
    this.listeners.forEach(listener => listener(path));
  },
  listen(listener) {
    this.listeners.push(listener);
  }
};

// Function to set default path to /home-page
function setDefaultPath(path = '/home-page') {
  if (window.location.pathname === '/') {
    history.push(path);
  }
}

// Router component
function BrowserRouter({ children }) {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    const onLocationChange = () => {
      setCurrentPath(window.location.pathname);
    };

    setDefaultPath(); // Set default path on initial load

    window.addEventListener('popstate', onLocationChange);
    return () => {
      window.removeEventListener('popstate', onLocationChange);
    };
  }, []);

  let activeChild = null;

  React.Children.forEach(children, child => {
    if (child.props.path === currentPath) {
      activeChild = child;
    }
  });

  return activeChild ? activeChild : <NotFoundPage />;
}

// Route component
function Route({ path, element }) {
  return element;
}

// Link component
function Link({ to, children }) {
  const handleClick = (event) => {
    event.preventDefault();
    history.push(to);
  };

  return (
    <a href={to} onClick={handleClick}>
      {children}
    </a>
  );
}

function Router() {
  return (
    <BrowserRouter>
      {Object.keys(modules).map((moduleName) => {
        const Component = modules[moduleName];
        const path = camelToKebab(moduleName).toLowerCase();
        return <Route key={path} path={`/${path}`} element={<Component />} />;
      })}
    </BrowserRouter>
  );
}

*/

// Route function
function route(element, strict = 'none') {
  // let urlTitle = camelToKebab(element.type.name).toLowerCase().substring(1);
  // window.history.pushState(null, '', `/${urlTitle}`);

  if (strict === 'none') {
    root.render(element);
  } else if (strict === 'strict') {
    root.render(
      <React.StrictMode>
        {element}
      </React.StrictMode>
    );
  }
}

export { root, route };
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { lightenDarkenColor, secondaryColor, setOpacity } from '../../utils/projectColors';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, texthoverbgcolor, texthovercolor, display }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: setOpacity(lightenDarkenColor(texthoverbgcolor, -35), .6),
    color: texthovercolor,
    borderRadius: "5px",
    transition: "all 0.3s ease",
    backdropFilter: "blur(10px)",
    fontSize: "13px",
    display: display
  },
}));

function NiceButton({ 
  icon, className, onClick=()=>{}, hasIcon=false, text, sx={}, useArea=false, height="50px", width="50px", hoverText="",
  fontColor="white", bgColor='#003687', hoverColor=secondaryColor, area="50px", fontSize="20px", useHoverText=false, 
  onHover=()=>{}, onLeave=()=>{}, useChildren=false, children, textHoverColor="#fff", textHoverBgColor='#003687' 
}) {
  return (
    <CustomTooltip 
      title={hoverText} 
      texthovercolor={textHoverColor}
      texthoverbgcolor={textHoverBgColor}
      display={useHoverText ? 'block' : 'none'}
    >
      <Button
        className={className}
        sx={{
          backgroundColor: bgColor,
          color: fontColor,
          borderRadius: "10px",
          transition: "all 0.3s ease",
          margin: 0,
          height: useArea ? area : height,
          width: useArea ? area : width,
          '&:hover': {
            backgroundColor: hoverColor,
            transform: "scale(1.1)",
          },
          ...sx
        }}
        onMouseEnter={(e) => {
          onHover(e)
        }}
        onMouseLeave={(e) => {
          onLeave(e)
        }}
        onClick={(e) => onClick(e)}
      >
        {useChildren ? 
          children 
          :
          hasIcon ?
            <FontAwesomeIcon icon={icon} color={fontColor} fontSize={fontSize} />
            :
            text
        }
      </Button>
    </CustomTooltip>
  )
};

export default NiceButton;
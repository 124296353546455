import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { route } from '../../utils/router.js';
import { secondaryColor } from '../../utils/projectColors.js';
import { adjustOnScreenSize } from "../../utils/detectDevice.js";

import HomePage from "../../pages/Home-page/homePage.jsx";

function BackButton({ className, toComponent=<HomePage />, sx={}, backgroundColor="#003687", arrowColor="#fff", hoverColor=secondaryColor, beforeBack=()=>{} }) {
  const [buttonWidth, setButtonWidth] = useState(50);
  const [buttonHeight, setButtonHeight] = useState(50);
  
  const goBack = (e) => {
    beforeBack(e);
    route(toComponent)
  };

  useEffect(() => {
    window.addEventListener("resize", (ev) => {
      let [wKoeficient, hKoeficient] = adjustOnScreenSize();

      setButtonWidth(10*wKoeficient);
      setButtonHeight(10*hKoeficient);
    });

    return () => {
      window.removeEventListener("resize", (ev) => {
        console.log("Removing event listener");
      });
    }
  }, []);

  return (
    <Button 
      onClick={(e) => goBack(e)}
      className={`back-button ${className}`}
      sx={{
        position: "fixed",
        top: `10px`,
        left: `10px`,
        backgroundColor: backgroundColor,
        color: arrowColor,
        borderRadius: "10px",
        transition: "all 0.3s ease",
        height: `50px`,
        width: `50px`,
        '&:hover': {
          backgroundColor: hoverColor,
          transform: "scale(1.1)",
        },
        ...sx
      }}
    >
      <FontAwesomeIcon color={arrowColor} icon={faArrowLeft} fontSize={'20px'}/>
    </Button>
  );
}

export default BackButton;
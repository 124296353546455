import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import alertIcon from '../../assets/images/alert-icon.png'
import messageIcon from '../../assets/images/message-icon.png'

import './alert.scss';

function AreYouSurePopup({ className, message, onConfirm=()=>{}, onCancel=()=>{}, onClose=()=>{}, open, cancelText='Cancel', confirmText='Yes', alert=false, messageAlert=false, titleText="Alert!", hasCloseButton=false, sx={} }) {
  const closePopup = (event, type) => {
    let popup = document.querySelector(`.${className}`);
    let bgBlur = document.querySelector(`.bg-blur-${className}`);

    popup.classList.remove('popup-move-in-mn');
    popup.classList.add('popup-move-out-mn');
    bgBlur.classList.remove('blur-active');

    if (type === 'confirm') {
      onConfirm(event);
    } 
    else if (type === 'cancel') {
      onCancel(event);
    } else if (type === 'close') {
      onClose(event);
    }
    else {
      console.error('How???');
    }
  };

  useEffect(() => {
    let popup = document.querySelector(`.${className}`);
    let bgBlur = document.querySelector(`.bg-blur-${className}`);

    if (open) {
      popup.classList.remove('popup-move-out-mn');
      popup.classList.add('popup-move-in-mn');
      bgBlur.classList.add('blur-active');
    } else {
      // pass
    }
  }, [open]);

  const coolButtonStyles = {
    backgroundColor: 'var(--sec-color)',
    color: 'white',
    padding: '5px 10px',
    boxSizing: 'border-box',
    width: '1fr',
    height: '50px',
    '&:hover': {
      backgroundColor: 'var(--third-color)',
      color: 'white',
    },
  }

  let icon;

  if (alert) {
    icon = alertIcon;
  } else if (messageAlert) {
    icon = messageIcon;
  } else {
    icon = alertIcon;
  }

  return (
    <>
      <div className={`${className} are-you-sure-popup`} style={{...sx}}>
        {hasCloseButton && <Button
          onClick={(e) => { closePopup(e, 'close') }}
          sx={{
            position: 'absolute',
            top: '5px',
            right: '5px',
            backgroundColor: 'var(--sec-color)',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '10px',
            '&:hover': {
              backgroundColor: 'var(--third-color)',
              color: 'white',
            },
          }}
        >
          X
        </Button>}
        <div className="message">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems:'center' }}>
            <img src={icon} alt="alert" className="alert-img" />
            <h1>{titleText}</h1>
          </div>
          <p style={{ margin: '10px' }}>{message}</p>
        </div>
        <div className="buttons">
          <Button 
            fullWidth
            onClick={(e) => { closePopup(e, 'confirm') }}
            sx={{
              ...coolButtonStyles,
              borderRadius: '0',
              borderBottomLeftRadius: '10px',
            }}
          >
            {confirmText}
          </Button>
          <Button 
            fullWidth
            onClick={(e) => { closePopup(e, 'cancel') }}
            sx={{
              ...coolButtonStyles,
              borderRadius: '0',
              borderBottomRightRadius: '10px',
            }}
          >
            {cancelText}
          </Button>
        </div>
      </div>

      <div className={`bg-blur-${className} bg-blur`} />
    </>
  );
}

export default AreYouSurePopup;
import React, { useState, useEffect, Suspense } from 'react';
import { Button, Box, Typography, TextField, Grid } from '@mui/material';
import { Google as GoogleIcon } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import bgVideo from '../../assets/videos/dynamic-bg-loop.mp4';

import './signin.scss';
import BackButton from '../../components/BackButton/BackButton';
import Loader from '../../components/Loader/Loader';
import { accentColor, getTheme } from '../../utils/projectColors';
import { initiateGoogleSignInRedirect, signInEmail, signInWithGooglePopup, signInWithGoogleRedirect, signUpEmail } from '../../scripts/accountManager';
import { route } from '../../utils/router';
import HomePage from '../Home-page/homePage';
import { isMobileDevice } from '../../utils/detectDevice';

function SignIn() {
  const [activeShow, setActiveShow] = useState('sign-in');
  const [loaded, setLoaded] = useState(false);

  const [signingIn, setSigningIn] = useState(false);

  const hasUpperCase = (str) => /[A-Z]/.test(str);
  const hasNumber = (str) => /\d/.test(str);
  const hasSpecialCharacter = (str) => /[@$!%*?&]/.test(str);

  useEffect(() => {
    const video = document.querySelector('.bg-video');

    const playVideo = () => {
      if (video) {
        video.play().catch(error => {
          console.error('Error attempting to play', error);
        });
      }
    };

    // Attempt to play the video on component mount
    playVideo();
    setTimeout(() => {
      playVideo();
    }, 1000);

    // Add event listener to play video on user interaction
    document.addEventListener('click', playVideo);
    document.addEventListener('touchstart', playVideo);

    // Set loaded to true after 1 second
    setTimeout(() => {
      setLoaded(true);
    }, 10);

    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('click', playVideo);
      document.removeEventListener('touchstart', playVideo);
    };
  }, []);

  const inputSX = {
    '& .MuiInputBase-input': {
      color: 'rgba(255, 255, 255, .7)', // Text color
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, .7)', // Label color
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(255, 255, 255, .7)', // Outline color when focused
      },
    },
  };

  // TODO: Add a flashcard streak system using reminders for studying after your "urnik" that is set up with travel time after sign up

  const handleSignInUp = () => {
    if (activeShow === 'sign-in') {
      let signInForm = document.querySelector('.sign-in-left-side');
      let signUpForm = document.querySelector('.sign-in-right-side');
      
      signUpForm.classList.remove('fly-in');
      signInForm.classList.add('fly-out');

      setTimeout(() => {
        signInForm.style.display = 'none';
        signUpForm.style.display = 'flex';

        signUpForm.classList.add('fly-in');
        signUpForm.classList.remove('fly-out');
      }, 1000);

      setActiveShow('sign-up');
    } else if (activeShow === 'sign-up') {
      let signInForm = document.querySelector('.sign-in-left-side');
      let signUpForm = document.querySelector('.sign-in-right-side');

      signInForm.classList.remove('fly-in');
      signUpForm.classList.add('fly-out');

      setTimeout(() => {
        signUpForm.style.display = 'none';
        signInForm.style.display = 'flex';

        signInForm.classList.add('fly-in');
        signInForm.classList.remove('fly-out');
      }, 1000);

      setActiveShow('sign-in');
    } else {
      alert('Error: Invalid active show value');
    }
  }

  const handleSignInEmail = async (event) => {
    event.preventDefault();

    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    try {
      if (activeShow === 'sign-in') {
        let email = document.getElementById('in-email').value;
        let password = document.getElementById('in-password').value;

        if (email === '') {
          toast.error('Please enter an email address!');
          return;
        } else if (password === '') {
          toast.error('Please enter a password!');
          return;
        } else if (password !== '' && email !== '' && emailRegex.test(email)) {
          let resultNew = await signInEmail(email, password);
          
          if (resultNew.signInError === "invalid-credential") {
            toast.error('Invalid email address!');
            return;
          }
        } else if (password.length <= 8) {
          toast.error('Password must be at least 8 characters!');
          return;
        } else if (!(emailRegex.test(email))) {
          toast.error('Invalid email address!');
          return;
        } 

        setSigningIn(true);

        let response = await signInEmail(email, password);

        setTimeout(() => {
          setSigningIn(false);
        }, 500);

        if (response.success) {
          toast.success('Signed in successfully with email!');
          setTimeout(() => {
            route(<HomePage />);
          }, 500);
        } else if (!response.success) {
          if (response.signInError === 'user-not-found') {
            toast.error('No user found with this email!');
          } else if (response.signInError === 'password') {
            toast.error('Incorrect password!');
          } else {
            toast.error('Error signing in with email!');
          }
        }

      } else if (activeShow === 'sign-up') {
        let email = document.getElementById('up-email').value;
        let password = document.getElementById('up-password').value;
        let username = document.getElementById('up-username').value;

        if (email === '') {
          toast.error('Please enter an email address!');
          return;
        } else if (password === '') {
          toast.error('Please enter a password!');
          return;
        } else if (username === '') {
          toast.error('Please enter a username!');
          return;
        } else if (password.length <= 8) {
          toast.error('Password must be at least 8 characters!');
          return;
        } else if (!hasUpperCase(password)) {
          toast.error('Password must contain at least one uppercase letter!');
          return;
        } else if (!hasNumber(password)) {
          toast.error('Password must contain at least one number!');
          return;
        } else if (!hasSpecialCharacter(password)) {
          toast.error('Password must contain at least one special character!');
          return;
        } else if (!(emailRegex.test(email))) {
          toast.error('Invalid email address!');
          return;
        } 

        setSigningIn(true);

        let response = await signUpEmail(email, password, username);

        setSigningIn(false);

        if (response.success) {
          toast.success('Signed up successfully with email!');
          setTimeout(() => {
            route(<HomePage />);
          }, 500);
        } else if (!response.success) {
          toast.error('Error signing up with email!');
        }
      } else {
        console.error('Error: Invalid active show value');
        toast.error('Error signing in with email!');
      }
    } catch (error) {
      console.error('Error signing in with email: ', error);
      toast.error('Error signing in with email!');
    }
  }

  const handleSignInGoogle = async (event, isMobile=false) => {
    event.preventDefault();

    try {
      let result = null;

      let isMobileDev = !isMobile? isMobileDevice() : isMobile;

      if (!isMobileDev) {
        result = await signInWithGooglePopup();
      } else {
        // TODO: Implement redirect sign in for mobile devices and fix its bugs
        result = await signInWithGooglePopup();
      }

      if (result.status) {
        toast.success('Signed in successfully with Google!');
        setTimeout(() => {
          route(<HomePage />);
        }, 1000);
      } else if (!result.status) {
        if (result.error === 'popup blocked') {
          if (isMobile) {
            toast.error('Sign-In popup was blocked. Please allow popups for this site.');
          } else {
            await handleSignInGoogle(event, true);
          }
        } else {
          toast.error(`Error signing in with Google! ${result.error}`);
        }
      }
    } catch (error) {
      console.error('Error signing in with Google: ', error);
      toast.error('Error signing in with Google!');
    }
  }


  if (!loaded) {
    return (
      <Loader primaryColor={accentColor} scale={2} />
    );
  }

  return (
    <Suspense fallback={<Loader primaryColor={accentColor} scale={2} />}>
      <div className='sing-in-main'>
        <video 
          src={bgVideo}
          className='bg-video' 
          autoPlay 
          loop 
          muted
          playsInline
          controls={false}
        />
          <div className='signIn-inner'>
            <BackButton 
              sx={{ position: 'absolute', top: '20px', left: '20px' }} 
              backgroundColor='rgba(255, 255, 255, .1)'
              hoverColor='rgba(255, 255, 255, .3)'
            />

            <div className='sign-in-left-side'>
              <Typography component="h1" variant="h3">
                Sign In
              </Typography>
              <Box component="form" method='POST' noValidate 
                onSubmit={(event) => handleSignInEmail(event)}
                sx={{ 
                  mt: 1, 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '20px',
                  boxSizing: 'border-box',
                  width: '100%'
                }}
              >
                <TextField
                  margin="normal"
                  required
                  id="in-email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={{ width: '90%', ...inputSX }}
                />
                <TextField
                  margin="normal"
                  required
                  name="password"
                  label="Password"
                  type="password"
                  id="in-password"
                  autoComplete="current-password"
                  sx={{ width: '90%', ...inputSX }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ 
                    mt: 3, 
                    mb: 2, 
                    width: '60%',
                    backgroundColor: 'rgba(255, 255, 255, .2)',
                    color: 'rgba(255, 255, 255, .7)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, .3)',
                      color: 'rgba(255, 255, 255, .9)'
                    }
                  }}
                >
                  Sign In
                  {signingIn ?
                    <Loader type='square-of-dots' scale={.5} boxed />
                    :
                    null
                  }
                </Button>
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  onClick={() => handleSignInUp()}
                  sx={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    transition: 'color .4s',
                    '&:hover': {
                      color: 'rgba(255, 255, 255, .5)',
                      cursor: 'pointer'
                    }
                  }}
                >
                  Don't have an account? Sign up
                </Typography>
                <div className='or-divider'/>
                <Grid container justifyContent="center" width="100%">
                  <Grid item width="90%">
                    <Button
                      variant="outlined"
                      startIcon={<GoogleIcon />}
                      fullWidth
                      onClick={(event) => handleSignInGoogle(event)}
                      sx={{ 
                        mt: 2, 
                        backgroundColor: 'rgba(255, 255, 255, .2)', 
                        color: 'rgba(255, 255, 255, .7)',
                        border: '1px solid rgba(255, 255, 255, .7)',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: 'rgba(255, 255, 255, .9)',
                          border: '1px solid rgba(255, 255, 255, .9)'
                        } 
                      }}
                    >
                      Sign in with Google
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </div>

            <div className='sign-in-right-side'>
              <Typography component="h1" variant="h3" type="sign-up">
                Sign Up
              </Typography>
              <Box component="form" method='POST' noValidate 
                onSubmit={(event) => handleSignInEmail(event)}
                sx={{ 
                  mt: 1, 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '20px',
                  boxSizing: 'border-box',
                  width: '100%'
                }}
              >
                <TextField
                  margin="normal"
                  required
                  id="up-username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  sx={{ width: '90%', ...inputSX }}
                />
                <TextField
                  margin="normal"
                  required
                  id="up-email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  sx={{ width: '90%', ...inputSX }}
                />
                <TextField
                  margin="normal"
                  required
                  name="password"
                  label="Password"
                  type="password"
                  id="up-password"
                  autoComplete="current-password"
                  sx={{ width: '90%', ...inputSX }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ 
                    mt: 3, 
                    mb: 2, 
                    width: '60%',
                    backgroundColor: 'rgba(255, 255, 255, .2)',
                    color: 'rgba(255, 255, 255, .7)',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, .3)',
                      color: 'rgba(255, 255, 255, .9)'
                    }
                  }}
                >
                  Sign Up
                  {signingIn ?
                    <Loader type='square-of-dots' scale={.5} boxed />
                    :
                    null
                  }
                </Button>
                <Typography 
                  variant="body2" 
                  color="text.secondary" 
                  onClick={() => handleSignInUp()}
                  sx={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    transition: 'color .4s',
                    '&:hover': {
                      color: 'rgba(255, 255, 255, .5)',
                      cursor: 'pointer'
                    }
                  }}
                >
                  Already have an account? Sign in
                </Typography>
                <div className='or-divider'/>
                <Grid container justifyContent="center" width="100%">
                  <Grid item width="90%">
                    <Button
                      variant="outlined"
                      startIcon={<GoogleIcon />}
                      onClick={(event) => handleSignInGoogle(event)}
                      fullWidth
                      sx={{ 
                        mt: 2, 
                        backgroundColor: 'rgba(255, 255, 255, .2)', 
                        color: 'rgba(255, 255, 255, .7)',
                        border: '1px solid rgba(255, 255, 255, .7)',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: 'rgba(255, 255, 255, .9)',
                          border: '1px solid rgba(255, 255, 255, .9)'
                        } 
                      }}
                    >
                      Sign in with Google
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            pauseOnHover={false}
            closeOnClick
            rtl={false}
            theme={getTheme()}
          />
      </div>
    </Suspense>
  );
}

export default SignIn;
import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';
import JSXParser from 'react-jsx-parser';
import { firestore } from "../config/firebaseConfig.js";
import { collection, getDocs } from 'firebase/firestore';
import { toPng } from 'html-to-image';


export function nothing() {
  return null;
}

export function UserImage({ username }) {
  const firstLetter = username.charAt(0).toUpperCase();

  return (
    <div style={{
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '20px',
      color: '#fff'
    }}>
      {firstLetter}
    </div>
  );
}

// Parse the text to include special text formatting
export const formatText = (text) => {
  let newText = text;

  // Bold
  newText = newText.replace(/\*\*(.*?)\*\*/g, `<b>$1</b>`);

  // Italic
  newText = newText.replace(/_(.*?)_/g, "<i>$1</i>");

  // Strikethrough
  newText = newText.replace(/--(.*?)--/g, "<s>$1</s>");

  // Superscript
  newText = newText.replace(/\^(.*?)\^/g, "<sup>$1</sup>");

  // Subscript
  newText = newText.replace(/~(.*?)~/g, "<sub>$1</sub>");

  return newText;
}

// Helper function to convert link to image source
/**
 * Asynchronously converts a given link to an image source.
 * 
 * @param {string} link - The link to convert to an image source.
 * @returns {Promise<string|null>} A Promise that resolves with the image source URL if successful, or null if an error occurs.
 */
export async function convertLinkToImgSrc(link) {
  try {
    const response = await fetch(link);
    const blob = await response.blob();
    
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting link to image source:", error);
    return null;
  }
}

// Helper function to scale an image
/**
 * Asynchronously scales an image to the specified width and height.
 * 
 * @param {string} dataUrl - The data URL of the image to be scaled.
 * @param {number} [width=200] - The target width of the scaled image (by default 200).
 * @param {number} [height=200] - The target height of the scaled image (by default 200).
 * @returns {Promise<string>} A promise that resolves with the data URL of the scaled image.
 */
export async function scaleImage(dataUrl, width=200, height=200) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
    img.onerror = reject;
    img.src = dataUrl;
  });
}

/**
 * Converts a given first letter into an image source by creating a styled div element with the letter.
 * The div element is styled with specific dimensions, border radius, background color, text properties, and alignment.
 * Uses the 'toPng' function to convert the div element into a PNG image data URL.
 * @param {string} firstLetter - The first letter to be displayed in the image.
 * @returns {Promise<string>} A data URL representing the converted image of the first letter.
 * @throws {Error} If an error occurs during the conversion process.
 */
export async function convertDivToImgSrc(firstLetter) {
  const node = document.createElement('div');
  node.style.width = '500px';
  node.style.height = '500px';
  node.style.borderRadius = '50%';
  node.style.backgroundColor = '#ccc';
  node.style.display = 'flex';
  node.style.justifyContent = 'center';
  node.style.alignItems = 'center';
  node.style.fontSize = '350px';
  node.style.fontWeight = 'bold';
  node.style.fontFamily = 'Arial, sans-serif';
  node.style.color = '#fff';
  node.textContent = firstLetter.toUpperCase();

  try {
    const dataUrl = await toPng(node, { quality: 1, width: 500, height: 500 });
    return dataUrl;
  } catch (error) {
    console.error('oops, something went wrong!', error);
  }
}

// Check if a value exists in a nested dictionary
function dictHasValue(obj, value) {
  // Check if the input is an object
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  // Iterate over the keys of the object
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === value) {
        return true;
      }
      // If the value is an object, recursively check it
      if (typeof obj[key] === 'object') {
        if (dictHasValue(obj[key], value)) {
          return true;
        }
      }
    }
  }

  // Return false if no undefined value is found
  return false;
}

// generate a ID for a collection of data in firestore
const generateID = async () => {
  let ID_length = 8;

  /** 
   * depth of ID
   * @param num: add numbers
   * @param lower: add lowercase letters
   * @param upper: add uppercase letters
   * @param special: add special characters
   * @param extra-special: add extra special characters (may not be supported by all systems)
  */ 
  let DI_depth = 'num, lower, upper';

  let allIds;

  try {
    allIds = await getDocs(collection(firestore, 'flashcards'));
  } catch (error) {
    console.error('Error getting all IDs:', error);
    return null;
  }

  // decode depth let
  let depth = DI_depth.split(', ');
  let num = depth.includes('num');
  let lower = depth.includes('lower');
  let upper = depth.includes('upper');
  let not_so_special = depth.includes('special');
  let special = depth.includes('extra-special');

  // generating the id using the params
  let ID = '';
  let chars = '';
  if (num) {
    chars += '0123456789';
  }
  if (lower) {
    chars += 'abcdefghijklmnopqrstuvwxyz';
  }
  if (upper) {
    chars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }
  if (not_so_special) {
    chars += '!@#$%?_';
  }
  if (special) {
    chars += '!@#$%^&*()_+-=[]{}|;:,.<>?';
  }

  // generate the ID
  for (let i = 0; i < ID_length; i++) {
    ID += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  // check if the ID is unique
  if (allIds.docs.some(doc => doc.id === ID)) {
    return await generateID();
  } else {
    return ID;
  }
}

// Check type of item
const checkType = (item) => {
  if (Array.isArray(item)) {
    return 'array';
  } else if (typeof item === 'object' && item !== null) {
    return 'object';
  } else {
    return typeof item;
  }
}

// Convert camel case to kebab case
function camelToKebab(string) {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
}

// Format a date object to a custom string format
const formatDate = (date, hasSeconds=true) => {
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return (
    hasSeconds?
    `${day}/${month}/${year}-${hours}:${minutes}:${seconds}`
    :
    `${day}/${month}/${year}`
  );
};

// parse jsx string to jsx element
const parseJSX = (jsxString) => {
  try {
    console.log('parsing JSX...');
    const element = <JSXParser jsx={jsxString} />;
    return element;
  } catch (error) {
    console.error('Error parsing JSX:', error);
    return null;
  }
};

// convert flashcard from jsx to string
const jsxToString = (flashcard) => {
  for (let j = 0; j < flashcard.cards.length; j++) {
    if (typeof flashcard.cards[j].back === "object") {
      if (flashcard.cards[j].back.type === JSXParser) {
        flashcard.cards[j].back = flashcard.cards[j].back.props.jsx;
      } else {
        flashcard.cards[j].back = reactElementToJSXString(flashcard.cards[j].back);
      }
    }
    if (typeof flashcard.cards[j].front === "object") {
      if (flashcard.cards[j].front.type === JSXParser) {
        flashcard.cards[j].front = flashcard.cards[j].front.props.jsx;
      } else {
        flashcard.cards[j].front = reactElementToJSXString(flashcard.cards[j].front);
      }
    }
  }
  return flashcard;
}

// check if string is html or jsx
const isHTMLorJSX = (str) => {
  const htmlRegex = /<[a-z][\s\S]*>/i;
  return htmlRegex.test(str.trim());
};

// convert flashcard from string to jsx
const parseToJSX = (flashcard) => {
  for (let j = 0; j < flashcard.cards.length; j++) {
    if (flashcard.cards[j] !== "end") {
      if (typeof flashcard.cards[j].back === "string" && isHTMLorJSX(flashcard.cards[j].back)) {
        flashcard.cards[j].back =  parseJSX(flashcard.cards[j].back);
      }
      if (typeof flashcard.cards[j].front === "string" && isHTMLorJSX(flashcard.cards[j].front)) {
        flashcard.cards[j].front = parseJSX(flashcard.cards[j].front);
      }
    }
  }

  return flashcard;
}

// Helper function to check if an object is a React element
const isReactElement = (element) => {
  return React.isValidElement(element);
};

// Custom deep copy function
const deepCopy = (obj) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepCopy);
  }

  if (isReactElement(obj)) {
    return React.cloneElement(obj);
  }

  const copy = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key]);
    }
  }
  return copy;
};


const convertNewlinesToBr = (str) => {
  if (typeof str !== 'string') {
    console.error('Input is not a string');
    return str;
  }
  str = str.replace(/\n/g, '<br/>');
  return str;
};

const convertBrToNewlines = (str) => {
  str = str.replace(/<br\s*\/?>/g, '\n')
  return str;
};

// Convert an image URL to a data URL
/**
 * Asynchronously converts an image from a given URL to a Data URL.
 * 
 * @param {string} url - The URL of the image to be converted.
 * @returns {Promise<string>} A Promise that resolves with the Data URL of the converted image.
 */
async function convertImageToDataURL(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };
    img.onerror = reject;
    img.src = url;
  });
}

export const hasUpperCase = (str) => /[A-Z]/.test(str);
export const hasNumber = (str) => /\d/.test(str);
export const hasSpecialCharacter = (str) => /[@$!%*?&]/.test(str);

// check valid prompt
export const validateCredential = (type, value) => {
  if (type === 'email') {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value === '') {
      return {
        success: false,
        error: 'email cannot be empty'
      };
    } else {
      if (emailRegex.test(value)) {
        return {
          success: true,
          error: null
        };
      } else {
        return {
          success: false,
          error: 'invalid email'
        };
      }
    }
    
  } else if (type === 'password') {
    if (value === '') {
      return {
        success: false,
        error: 'password cannot be empty'
      };
    } else {
      if (value.length <= 8) {
        return {
          success: false,
          error: 'password too short'
        };
      } else {
        if (!hasUpperCase(value)) {
          return {
            success: false,
            error: 'password must contain an uppercase letter'
          };
        } else {
          if (!hasNumber(value)) {
            return {
              success: false,
              error: 'password must contain a number'
            };
          } else {
            if (!hasSpecialCharacter(value)) {
              return {
                success: false,
                error: 'password must contain a special character'
              };
            } else {
              return {
                success: true,
                error: null
              };
            }
          }
        }
      }
    }
  } else {
    return {
      success: false,
      error: 'Invalid type'
    };
  }
}

// const convertToWebP = async (src) => {
//   try {
//     let buffer;
//     if (src.includes('data:image/jpeg')) {
//       buffer = Buffer.from(src.replace('data:image/jpeg;base64,', ''), 'base64');
//     } else if (src.includes('data:image/png')) {
//       buffer = Buffer.from(src.replace('data:image/png;base64,', ''), 'base64');
//     } else if (src.includes('data:image/jpg')) {
//       buffer = Buffer.from(src.replace('data:image/jpg;base64,', ''), 'base64');
//     } else if (src.includes('data:image/webp')) {
//       return {
//         success: true,
//         result: src,
//         error: null
//       };
//     } else {
//       return {
//         success: false,
//         result: null,
//         error: 'Invalid image format'
//       };
//     }

//     const webpBuffer = await sharp(buffer).webp().toBuffer();
//     const webpBase64 = `data:image/webp;base64,${webpBuffer.toString('base64')}`;

//     return {
//       success: true,
//       result: webpBase64,
//       error: null
//     };
//   } catch (error) {
//     return {
//       success: false,
//       result: null,
//       error: error.message
//     };
//   }
// };


export { 
  dictHasValue, 
  camelToKebab, 
  checkType, 
  formatDate, 
  parseJSX, 
  jsxToString, 
  isHTMLorJSX, 
  parseToJSX,
  deepCopy, 
  convertNewlinesToBr, 
  convertBrToNewlines, 
  generateID, 
  convertImageToDataURL,
  // convertToWebP
};
import  { GoogleGenerativeAI, HarmCategory, HarmBlockThreshold, } from "@google/generative-ai";
import { getCurrentUser } from "./accountManager";
import { formatDate } from "../utils/algorithms";

// safetySettings: Adjust safety settings
// See https://ai.google.dev/gemini-api/docs/safety-settings

const apiKey = process.env.REACT_APP_GEMINI_API_KEY;
const genAI = new GoogleGenerativeAI(apiKey);

const model = genAI.getGenerativeModel({
  model: "gemini-1.5-flash",
});

const generationConfig = {
  temperature: 1,
  topP: 0.95,
  topK: 64,
  maxOutputTokens: 8192,
  responseMimeType: "text/plain",
};

export async function askPromptFromNewChat(prompt) {
  const chatSession = model.startChat({
    generationConfig,
    history: [],
  });

  const result = await chatSession.sendMessage(prompt);
  console.log(result.response.text());
}

function parseJsonString(jsonString, check=false) {
  if (check) {
    // Extract the JSON part from the input string
    const jsonMatch = jsonString.match(/```json([\s\S]*?)```/);

    if (!jsonMatch) {
      console.log("Output: ", jsonString);
      throw new Error("Invalid input format");
    }
  }

  try {
    // Extract the JSON part from the input string (without the ```json``` part)
    const jsonContent = jsonString.replace(/```json([\s\S]*?)```/, "$1");

    // Parse the JSON string into a JavaScript object
    const jsonObject = JSON.parse(jsonContent);

    return jsonObject;
  } catch (error) {
    console.error("Failed to parse JSON string -> ", error);
    console.log("JSON String: ", jsonString);
  }
}

export async function generateFlashcardSet(subjectField, length, authorName, language='english', status=()=>{}) {
  // TODO: Update API key to work in all countries
  try {
    const flashcardSet = {
      author: authorName,
      authorUID: getCurrentUser().user.uid,
      cardSorting: false,
      date: formatDate(new Date(), false),
      id: "",
      path: "/",
      lastOpened: formatDate(new Date(), true),
      shuffleCards: false,
      progress: 0,
      timesViewed: 0,
    };

    const chatSession = model.startChat({
      generationConfig,
      history: [],
    });

    const query = `Generate a flashcard set with the javascript dictionary format so that you replace 
    the words in <> with the ai response Also the cards are made in a list inside the main dict under 
    the key "cards" so that the front side of the flashcard is named with the key 'front' and the 
    backside with 'back'. The subject will be provided after the example format. Here is the format 
    example: 
    {
      title: <the flashcard set subject in a short 1-3 word sentence with no capital letter and no period at the end and a max character length of 30 characters>,
      description: <a 1-20 word super short summary of the subject>
      cards: [
        {
          front: <the question for the subject on the front side of the first flashcard of the set>,
          back: <the answer for the subject on the back side of the first flashcard of the set>
        }
      ]
    }
      
    The subject for this flashcard set is about '${subjectField}' ${length === '/'? '' : `and the length of the set is '${length}' cards`}.
    Make sure the whole flashcard set is in '${language} language'.

    THE RESPONSE MUST ONLY BE IN THE JSON FORMAT SHOWN ABOVE IN THE EXAMPLE.
    `;

    status({status: "generating", response: null, error: null})

    const result = await chatSession.sendMessage(query);
    const resultText = result.response.text();
    const resultJSON = parseJsonString(resultText.trim());

    status({status: "generated", response: null, error: null})

    const finalResponse = {
      ...resultJSON,
      ...flashcardSet,
    };

    console.log(
      'Gemini Response: ',
      finalResponse
    );

    status({status: "success", response: finalResponse, error: null})
    return finalResponse;

  } catch (error) {
    status({status: "error", response: null, error: error})
    console.error(
      'Failed to generate flashcard set -> ',
      error
    );
  }
} 

export async function generateDiverseFlashcardSet(subjectField, length, authorName, status=()=>{}) {
  // TODO: Implement the generation of flashcard sets with images and different text layouts and images
}